import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { GenericModalDetails } from '@axon/axon-lib';
import { LoginService } from '@axon/data-access-lib';
import { EMPTY, Subscription, catchError, switchMap, of } from 'rxjs';

@Component({
    selector: 'ado-core-login-two-fa',
    templateUrl: './login-two-fa.component.html',
    styleUrls: ['./login-two-fa.component.scss'],
})
export class LoginTwoFaComponent implements AfterViewInit, OnDestroy {
    @ViewChild('code', { static: true }) code?: NgModel;
    data = '';
    invalidCode = false;
    private subscriptions = new Subscription();

    private details: GenericModalDetails = {
        header: 'login.2fa.send-email-header',
        body: 'login.2fa.send-email-body',
        cancelButton: 'login.2fa.ok',
        confirmButton: 'login.2fa.cancel',
    };

    constructor(private loginService: LoginService) {}

    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.code?.statusChanges
                ?.pipe(
                    switchMap(codeResponse => {
                        if (codeResponse === 'VALID') {
                            return this.loginService
                                .twoFactorAuthentication(this.code?.control.value)
                                .pipe(
                                    catchError(() => {
                                        this.invalidCode = true;
                                        this.data = '';
                                        return EMPTY;
                                    })
                                );
                        }
                        return of();
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
