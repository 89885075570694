import { Pipe, PipeTransform } from '@angular/core';
import { LoginService, UserDetails } from '@axon/data-access-lib';
import { Observable, map, of } from 'rxjs';

@Pipe({
    name: 'hasPermission',
    pure: false,
})
export class HasPermissionPipe implements PipeTransform {
    constructor(private authService: LoginService) {}

    transform(permissions: string[]): Observable<boolean> {
        if (!permissions || !this.authService) {
            return of(false);
        }

        return this.authService.userLoggedDetails.pipe(
            map((userDetails: UserDetails) => {
                return userDetails && userDetails.permissions
                    ? permissions.every((permission) => userDetails.permissions.includes(permission))
                    : false;
            })
        );
    }
}
