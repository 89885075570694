import { inject } from '@angular/core';
import { ResolveFn} from '@angular/router';
import { filter, Observable, take } from 'rxjs';
import { UserDetails } from '@axon/data-access-lib';
import { LoginService } from '@axon/data-access-lib';

export const userResolver: ResolveFn<UserDetails> = (): Observable<UserDetails> => {
    const loginService = inject(LoginService);

    return loginService.userLoggedDetails.pipe(
        filter(user => !!user),
        take(1)
    );
};
