import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, debounceTime } from 'rxjs';
import { NgbDropdownOption } from '../../models/ngb-dropdown-option.model';

/**
 * The AutocompleteComponent is a component designed to provide autocomplete
 * functionality with a dropdown for selecting options. It is built with a
 * focus on integration with Angular forms, support for translation, and keyboard navigation.
 */
@Component({
    selector: 'ado-core-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
    /**
     * ViewChild reference to the NgbDropdown instance.
     * Used to interact with the dropdown component in the template.
     */
    @ViewChild('dropdownRef', { static: false, read: NgbDropdown }) dropdown?: NgbDropdown;
    /**
     * ViewChildren query list of ElementRef for dropdown items.
     * Used to interact with the individual dropdown items in the template.
     */
    @ViewChildren('item') dropdownItems?: QueryList<ElementRef>;
    /**
     * Input property representing an array of dropdown options.
     */
    @Input() options?: NgbDropdownOption[];
    /**
     * Input property representing the placeholder text for the dropdown.
     */
    @Input() placeholder?: string;

    /**
     * FormControl used for handling and binding the value of the search option in the dropdown.
     */
    readonly searchOptionControl = new FormControl<string>('');

    /**
     * Represents the selected option in the dropdown.
     */
    selectedOption: NgbDropdownOption = { value: '', label: '' };
    /**
     * An array of dropdown options used for filtering in the dropdown list.
     */
    filteredOptions: NgbDropdownOption[] = [];
    /**
     * Indicates whether the dropdown is disabled or not.
     */
    isDisabled = false;
    /**
     * Indicates whether the dropdown is currently open or closed.
     */
    isOpen = false;
    /**
     * A collection of subscriptions to manage and unsubscribe from when necessary.
     */
    subscriptions = new Subscription();
    /**
     * Represents the index of the currently selected option in the dropdown.
     * It is initialized to -1 when no option is selected.
     */
    selectedIndex = -1;

    /**
     * Remember the value that was written.
     */
    private writtenValue = '';

    /**
     * A callback that is triggered when the value of the component changes.
     * @param arg - The new value of the component.
     */
    private _onChange?: (arg: string | null) => void;
    /**
     * A callback that is triggered when the component is touched.
     */
    private _onTouch?: () => void;
    /**
     * A callback that is triggered for validation purposes.
     */
    private _onValidation?: () => void;

    constructor(private cdr: ChangeDetectorRef, private translate: TranslateService) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.searchOptionControl.valueChanges.pipe(debounceTime(500)).subscribe((newValue) => {
                this.filterOptions(newValue);

                if (!this.dropdown?.isOpen()) {
                    this.dropdown?.open();
                }

                if (newValue === '') {
                    this._onChange?.(null);
                }
            })
        );

        this.subscriptions.add(
            this.translate.onLangChange.subscribe(() => {
                this.selectedOption.label &&
                    this.searchOptionControl.patchValue(this.translate.instant(this.selectedOption.label), { emitEvent: false });
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && this.options) {
            const result = this.options.find((option) => option.value === this.writtenValue);

            if (result) {
                this.selectedOption = result;
                this.searchOptionControl.patchValue(this.translate.instant(this.selectedOption.label), { emitEvent: false });
                this.filterOptions('');
            } else {
                this.filterOptions(this.selectedOption.label);
            }
        }
        this.cdr.markForCheck();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Filters the options based on the provided value.
     *
     * This function filters the available options based on a provided value,
     * considering the translated labels of the options.
     *
     * @param value - The value used to filter the options.
     */
    filterOptions(value: string | null): void {
        if (this.options) {
            this.filteredOptions = this.options.filter((option) =>
                this.translate.instant(option.label).toLowerCase().includes(value?.toLowerCase())
            );
            this.cdr.markForCheck();
        }
    }

    /**
     * Identifies the option based on its value.
     *
     * This function is used to determine the identifier of an option in a dropdown,
     * based on the option's value.
     *
     * @param index - The index of the option in the dropdown.
     * @param option - The option in the dropdown to which the function is applied.
     * @returns The value of the option, which will be used as the identifier.
     */
    identifyOptionByValue(index: number, option: NgbDropdownOption) {
        return option.value;
    }

    /**
     * Writes the provided value to the component.
     *
     * This function is part of the ControlValueAccessor interface and is used to
     * write a value to the component, updating the selected option and search input accordingly.
     *
     * @param value - The value to be written to the component.
     *
     * @example
     * Usage in an Angular form control:
     * myControl.setValue('someValue');
     */
    writeValue(value: string): void {
        this.writtenValue = value;

        if (this.options) {
            const result = this.options.find((option) => option.value === value);
            if (result) {
                this.selectedOption = result;
                this.searchOptionControl.patchValue(this.translate.instant(this.selectedOption.label), { emitEvent: false });
            } else {
                this.selectedOption = { value: '', label: '' };
            }
        }
        this.cdr.markForCheck();
    }

    /**
     * Registers a callback function to be called when the value changes.
     *
     * This function is part of the ControlValueAccessor interface and is used to register
     * a callback function that will be invoked when the value of the component changes.
     *
     * @param fn - The callback function to be registered.
     *
     * @example
     * Usage in an Angular form control:
     * myControl.registerOnChange(() => console.log('Value changed!'));
     */
    registerOnChange(fn: () => void): void {
        this._onChange = fn;
    }

    /**
     * Registers a callback function to be called when the component is touched.
     *
     * This function is part of the ControlValueAccessor interface and is used to register
     * a callback function that will be invoked when the component is touched (e.g., on blur).
     *
     * @param fn - The callback function to be registered.
     *
     * @example
     * Usage in an Angular form control:
     * myControl.registerOnTouched(() => console.log('Component touched!'));
     */
    registerOnTouched(fn: () => void): void {
        this._onTouch = fn;
    }

    /**
     * Sets the disabled state of the component.
     *
     * This function is part of the ControlValueAccessor interface and is used to set
     * the disabled state of the component.
     *
     * @param isDisabled - A boolean value indicating whether the component should be disabled.
     *
     * @example
     * Usage in an Angular form control:
     * myControl.setDisabledState(true);
     */
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this.cdr.markForCheck();
    }

    /**
     * Registers a callback function to be called when the validation changes.
     *
     * This optional function is part of the Validator interface and can be used to register
     * a callback function that will be invoked when the validation state of the component changes.
     *
     * @param fn - The callback function to be registered.
     *
     * @example
     * Usage in an Angular form control:
     * myControl.registerOnValidatorChange(() => console.log('Validation state changed!'));
     */
    registerOnValidatorChange?(fn: () => void): void {
        this._onValidation = fn;
    }

    /**
     * Validates the current value of the component.
     *
     * This function is part of the Validator interface and is used to perform custom validation
     * on the current value of the component.
     *
     * @returns A ValidationErrors object if the validation fails, or null if the validation passes.
     *
     * @example
     * Usage in an Angular form control:
     * const validationErrors = myControl.validate();
     * if (validationErrors) {
     *    console.log('Validation failed:', validationErrors);
     *  }
     */
    validate(): ValidationErrors | null {
        return null;
    }

    /**
     * Handles the selection of an option in the dropdown.
     *
     * This function is called when a user selects an option from the dropdown, updating
     * the selected option, search input, and triggering necessary callbacks.
     *
     * @param option - The selected option from the dropdown.
     */
    onSelectedOption(option: NgbDropdownOption): void {
        this.selectedOption = option;
        this.searchOptionControl.patchValue(this.translate.instant(this.selectedOption.label), { emitEvent: false });
        this.dropdown?.close();
        this.selectedIndex = -1;

        if (this._onChange && this._onTouch && this._onValidation) {
            this._onChange(option.value.toString());
            this._onTouch();
        }
        this.cdr.markForCheck();
    }

    /**
     * Handles the opening or closing of the dropdown.
     *
     * This function is called when the dropdown is opened or closed, updating the component's
     * internal state and triggering the touch callback if applicable.
     *
     * @param isOpen - A boolean indicating whether the dropdown is open.
     */
    onOpen(isOpen: boolean): void {
        this.isOpen = isOpen;
        if (this._onTouch) {
            this._onTouch();
        }
        this.cdr.markForCheck();
    }

    /**
     * Handles keyboard events for the dropdown.
     *
     * This function is called when a keyboard event occurs, such as arrow up, arrow down, or enter,
     * and performs actions accordingly, such as navigating through dropdown options or selecting an option.
     *
     * @param event - The keyboard event object.
     *
     */
    onKeyDown(event: KeyboardEvent) {
        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                if (this.selectedIndex > 0) {
                    this.selectedIndex--;
                    this.dropdownItems?.get(this.selectedIndex)?.nativeElement.scrollIntoView(true);
                }
                break;
            case 'ArrowDown':
                event.preventDefault();
                if (this.selectedIndex < this.filteredOptions.length - 1) {
                    this.selectedIndex++;
                    this.dropdownItems?.get(this.selectedIndex)?.nativeElement.scrollIntoView(true);
                }
                break;
            case 'Enter':
                event.preventDefault();
                if (this.selectedIndex !== -1) {
                    this.onSelectedOption(this.filteredOptions[this.selectedIndex]);
                }
                break;
            default:
                break;
        }
    }
}
