import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Environment, ENVIRONMENT } from '@axon-dot/fe/shared/environments';
import { DarkMode, DarkModeService, GenericModalComponent } from '@axon/axon-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subscription, distinctUntilChanged, switchMap, take } from 'rxjs';
import { LoginService } from '@axon/data-access-lib';
import { Role } from '@axon/data-access-lib';
import { LeaveSchedulesService } from '../../../services';

@Component({
    selector: 'ado-core-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
    @ViewChild('modalTemplate') modalTemplate!: TemplateRef<unknown>;
    darkMode = DarkMode.LIGHT;

    private uninitializedLeaveSchedulesModalSubscription = new Subscription();
    private isPublicMode = false;
    private isModalOpened = false;

    constructor(
        private darkModeService: DarkModeService,
        private leaveSchedules: LeaveSchedulesService,
        private modalService: NgbModal,
        private router: Router,
        private loginService: LoginService,
        @Inject(ENVIRONMENT) private env: Environment
    ) {
        this.isPublicMode = env.appMode === 'PUBLIC';
    }

    ngOnInit(): void {
        this.darkModeService.darkModeSubject$.subscribe((value) => {
            this.darkMode = value ? DarkMode.DARK : DarkMode.LIGHT;
        });
        if (!this.isPublicMode) {
            this.checkLeaveSchedulesIsInitialized();
        }
    }

    ngOnDestroy(): void {
        this.uninitializedLeaveSchedulesModalSubscription.unsubscribe();
    }

    private checkLeaveSchedulesIsInitialized() {
        const currentDate = new Date();

        this.uninitializedLeaveSchedulesModalSubscription = this.loginService.userLoggedDetails
            .pipe(
                take(1),
                distinctUntilChanged((previousUser, currentUser) => JSON.stringify(previousUser) === JSON.stringify(currentUser)),
                switchMap((userDetails) => {
                    if (!userDetails.roles.includes(Role.HR)) {
                        return EMPTY;
                    }
                    return this.leaveSchedules.isLeaveSchedulesInitialized(currentDate.getFullYear()).pipe(
                        switchMap<boolean, Observable<boolean>>((isInitialized) => {
                            if (isInitialized || this.isModalOpened) {
                                return EMPTY;
                            }
                            const modal = this.modalService.open(GenericModalComponent);
                            modal.componentInstance.details = {
                                header: 'features.leave-schedules.uninitialized-modal.title',
                                bodyTemplate: this.modalTemplate,
                                cancelButton: 'features.leave-schedules.uninitialized-modal.cancel-button',
                                confirmButton: 'features.leave-schedules.uninitialized-modal.confirm-button',
                                headerClass: 'd-flex justify-content-center',
                                confirmButtonClass: 'btn-success text-white',
                                cancelButtonClass: 'btn-danger',
                            };
                            this.isModalOpened = true;
                            return modal.closed;
                        })
                    );
                })
            )
            .subscribe((response) => {
                if (response === true) {
                    if (!this.isPublicMode) {
                        this.router.navigate(['/assign-holidays']);
                    } else {
                        window.location.href = this.env.localAppUrl;
                    }
                    this.isModalOpened = false;
                }
            });
    }
}
