import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef
} from '@angular/core';
import { TimelineItem, TimelineLinePosition } from '../../../models';
import { Constants } from '@axon/axon-lib';

@Component({
  selector: 'ado-core-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
  @Input() items: TimelineItem[] = [];
  @Input() linePosition: TimelineLinePosition = { position: 'end' };
  @Input() showDate = false;
  @ContentChild(TemplateRef) templateRef?: TemplateRef<TimelineItem>;
  dateFormat = Constants.DATE_TIME_FORMAT_DISPLAY;

  timelinePosition(linePosition: string, i: number): string {
    if (linePosition === 'center') {
      return i % 2 === 0 ? 'timeline left' : 'timeline right';
    }
    return '';
  }

  arrowPosition(linePosition: string, i: number): string {
    if (linePosition === 'center') {
      return i % 2 === 0 ? 'left-arrow' : 'right-arrow';
    } else if (linePosition === 'start') {
      return 'right-arrow';
    } else {
      return 'left-arrow';
    }
  }

  identifyItemById(index: number, item: TimelineItem) {
    return item.id;
}
}
