import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { filter, Observable, take } from 'rxjs';
import { TeamDto } from '../models';
import { TeamsService } from '../services';

export const teamsResolver: ResolveFn<TeamDto[]> = (): Observable<TeamDto[]> => {
    const teamsService = inject(TeamsService);

    return teamsService.getTeams().pipe(
        filter(teams => !!teams),
        take(1)
    );
};
