import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { COMPONENTS } from './components';
import { ModalsModule } from './components/modals';
import { PictureModule } from './components/picture/picture.module';
import { DIRECTIVES } from './directives';
import { PIPES } from './pipes';
import { SERVICES } from './services';
import { GenericFilterModule } from './components/filter/generic-filter.module';

const MODULES = [CommonModule, NgbModule, ReactiveFormsModule, FormsModule, ModalsModule, TranslateModule, PictureModule, GenericFilterModule];

@NgModule({
    declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
    imports: [...MODULES],
    exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
    providers: [...SERVICES],
})
export class AxonCoreLibModule {}
