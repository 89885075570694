import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './state';

export const selectRouterState = createFeatureSelector<RouterReducerState<State>>('router');

export const selectParams = createSelector(selectRouterState, (router) => router.state.params);

export const selectQueryParams = createSelector(selectRouterState, (router) => router.state.queryParams);

export const selectData = createSelector(selectRouterState, (router) => router.state.data);

export const selectUrl = createSelector(selectRouterState, (router) => router.state.url);

export const selectParamByName = (name: string) => createSelector(selectParams, (params) => params[name]);

export const selectQueryParamByName = (name: string) => createSelector(selectQueryParams, (queryParams) => queryParams[name]);

export const selectDataByName = (name: string) => createSelector(selectData, (data) => data[name]);
