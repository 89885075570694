<div class="ado-container h-100">
    <div class="row h-100">
        <div
            class="d-none d-md-block col-4 overflow-hidden bg-dark-subtle h-100 position-relative"
        >
            <img src="assets/images/Pattern.png" alt="My Happy SVG" />
            <ng-container [ngSwitch]="appMode">
                <img
                    *ngSwitchCase="'PUBLIC'"
                    src="assets/images/logo.png"
                    class="img-fluid img-logo position-absolute"
                    alt="Axon Soft Logo"
                    loading="lazy"
                />
                <img
                    *ngSwitchCase="'LOCAL'"
                    class="img-fluid img-logo position-absolute"
                    src="assets/images/logo-local.png"
                    alt="Axon Soft Logo - Local"
                    loading="lazy"
                />
            </ng-container>
        </div>
        <div class="col-md-8 col-12 p-0">
            <div class="d-flex flex-column h-100">
                <mat-card
                    class="ado-card px-3 px-sm-6 px-md-10 px-sm-6 pt-4 pb-5 col-11 col-sm-8 col-md-8 col-lg-6 m-auto"
                >
                    <mat-card-content class="d-block">
                        <div class="text-center p-2">
                            <h1>{{ 'login.welcome' | translate }}</h1>
                        </div>
                        <form [formGroup]="loginForm">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    class="form-control"
                                    formControlName="username"
                                    adoCoreValidationError="username"
                                    validationErrorBackgroundColor="#fff"
                                    placeholder="username"
                                    id="username"
                                />
                                <label for="username" class="form-label">{{
                                    'login.username' | translate
                                }}</label>
                            </div>

                            <div class="form-floating">
                                <input
                                    type="password"
                                    class="form-control"
                                    formControlName="password"
                                    adoCoreValidationError="password"
                                    validationErrorBackgroundColor="#fff"
                                    placeholder="password"
                                    id="password"
                                />
                                <label for="password" class="form-label">{{
                                    'login.password' | translate
                                }}</label>
                            </div>

                            <div class="row mb-3">
                                <div
                                    class="col d-flex justify-content-start align-items-center"
                                >
                                    <input
                                        type="checkbox"
                                        formControlName="rememberMe"
                                        name="rememberMe"
                                        class="me-1"
                                    />
                                    {{ 'login.remember-me' | translate }}
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <a
                                        routerLinkActive="active"
                                        routerLink="/forgot-password"
                                    >
                                        {{
                                            'login.forgot-password' | translate
                                        }}
                                    </a>
                                </div>
                            </div>
                            <div
                                class="invalid-credentials mb-3 d-flex justify-content-center"
                            >
                                <span
                                    *ngIf="credentialsInvalid && !inactiveUser"
                                    class="text-danger text-center"
                                    >{{
                                        'login.wrong-credentials' | translate
                                    }}</span
                                >
                                <span
                                    *ngIf="inactiveUser"
                                    class="text-danger text-center"
                                >
                                    {{ 'login.inactive-user' | translate }}
                                </span>
                            </div>
                            <div class="my-3 form-floating text-center">
                                <button
                                    (click)="validateRecaptcha()"
                                    class="btn btn-secondary text-white w-60 mb-1"
                                    [disabled]="!loginForm.valid"
                                >
                                    {{ 'login.action' | translate }}
                                </button>
                            </div>
                        </form>
                    </mat-card-content>
                </mat-card>

                <footer class="w-100">
                    <div
                        class="d-flex justify-content-center position-relative m-0 p-0 flex-row"
                    >
                        <div class="axon-soft-label p-3">
                            <small
                                ><a
                                    href="https://axon-soft.com/"
                                    class="text-muted"
                                    >Axon-soft &copy; {{ currentYear }}</a
                                ></small
                            >
                        </div>

                        <div class="dropdown p-2 position-absolute top-0">
                            <button
                                class="btn"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div class="row">
                                    <div class="col">
                                        <a>
                                            <img
                                                [src]="
                                                    'assets/images/languages/' +
                                                    selectedLanguage.translate +
                                                    '.png'
                                                "
                                                width="20px"
                                                height="20px"
                                                class="rounded-circle"
                                            />
                                        </a>
                                    </div>
                                    <div class="col">
                                        {{ selectedLanguage.text | translate }}
                                    </div>
                                    <div class="col">
                                        <div class="dropdown-toggle"></div>
                                    </div>
                                </div>
                            </button>
                            <ul class="dropdown-menu">
                                <li
                                    *ngFor="
                                        let lang of languages;
                                        trackBy: identifyLanguageByTranslate
                                    "
                                >
                                    <a
                                        class="dropdown-item d-flex align-items-center cursor-pointer"
                                        (click)="onChangeLanguage(lang)"
                                    >
                                        <img
                                            [src]="
                                                'assets/images/languages/' +
                                                lang.translate +
                                                '.png'
                                            "
                                            width="20px"
                                            height="20px"
                                            class="me-2 rounded-circle"
                                        />
                                        {{ lang.text | translate }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</div>

<re-captcha
    #recaptcha
    (resolved)="onResolvedReCaptcha($event)"
    size="invisible"
>
</re-captcha>
