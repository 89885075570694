import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { LoadingScope } from '../decorators';
import { AccessTokenDetailsDecode } from '../models/access-token-details-decode.model';
import { Credentials, Role, UserDetails, UserLoginResponse } from '../models/login.model';
import { UserService } from './user.service';
import { allTranslationLanguages } from '../data';

@Injectable({ providedIn: 'root' })
export class LoginService {
    userLoggedAccess = new BehaviorSubject<UserLoginResponse | null>(null);
    private userLoggedDetails$ = new BehaviorSubject<UserDetails | null | undefined>(undefined);
    private accessTokenDetailsDecode?: AccessTokenDetailsDecode;
    private loggedUsername = '';

    constructor(
        private http: HttpClient,
        private router: Router,
        private readonly translate: TranslateService,
        private userService: UserService
    ) {
        console.warn('LoginService Was Born');
    }

    public get userLoggedDetails(): Observable<UserDetails> {
        return this.userLoggedDetails$.asObservable().pipe(
            filter((user) => !!user),
            map((user) => user as UserDetails)
        );
    }

    public get userLoggedDetailsPossiblyNull(): Observable<UserDetails | null> {
        return this.userLoggedDetails$.asObservable().pipe(
            filter((user) => user !== undefined),
            map((user) => user as UserDetails | null)
        );
    }

    public get tokenDetailsDecoded(): AccessTokenDetailsDecode | undefined {
        return this.accessTokenDetailsDecode;
    }

    public get twoFaEnabled(): boolean {
        return this.accessTokenDetailsDecode ? this.accessTokenDetailsDecode.is2FAEnabled : false;
    }

    public set twoFaEnabled(is2FAEnabled: boolean) {
        this.accessTokenDetailsDecode = {
            ...this.accessTokenDetailsDecode,
            is2FAEnabled,
        };
    }

    logout(): void {
        this.http
            .post('api/logout', {})
            .pipe(
                catchError((errorRes) => {
                    this.router.navigate(['/login']);
                    if (!errorRes.error) {
                        return throwError(() => errorRes);
                    }
                    return throwError(() => errorRes.error);
                })
            )
            .subscribe(() => {
                this.userLoggedAccess.next(null);
                localStorage.removeItem('userData');
                this.router.navigate(['/login']);
            });
    }

    login(username: string, password: string) {
        return this.http
            .post<Credentials>(`/api/login`, {
                username: username,
                password: password,
            })
            .pipe(
                catchError((errorRes) => {
                    //TODO: possible refactor to throw same kind of error from here
                    if (!errorRes.error) {
                        return throwError(() => errorRes);
                    }
                    return throwError(() => errorRes.error);
                }),
                tap((resData: any) => {
                    this.userLoggedAccess.next(resData);
                    localStorage.setItem('userData', JSON.stringify(resData));
                    this.accessTokenDetailsDecode = jwt_decode(resData.accessToken);
                }),
                switchMap(() => {
                    this.loggedUsername = username;
                    if (!this.twoFaEnabled) {
                        return this.getUserDetails();
                    }
                    this.router.navigate(['/login/two-fa']);
                    return of(null);
                }),
                tap((resData: UserDetails | null) => {
                    if (resData) {
                        this.redirectUserByRole(resData.roles);
                    }
                })
            );
    }

    sendDeactivationEmail(): Observable<void> {
        return this.http.put<void>(`/api/2fa/deactivation?username=${this.loggedUsername}`, null);
    }

    deactivate2FA(username: string, password: string, token: string): Observable<void> {
        return this.http.post<void>(`/api/2fa/deactivation`, { username, password, token });
    }

    @LoadingScope()
    twoFactorAuthentication(code: string) {
        return this.http.post<UserLoginResponse>(`/api/login-2fa`, { code }).pipe(
            tap((resp) => {
                this.userLoggedAccess.next(resp);
                localStorage.setItem('userData', JSON.stringify(resp));
            }),
            switchMap(() => this.getUserDetails()),
            tap((resp: UserDetails) => {
                this.redirectUserByRole(resp.roles);
            })
        );
    }

    refresh(): Observable<UserLoginResponse> {
        return this.http
            .post<UserLoginResponse>(`/api/refresh`, {
                token: this.userLoggedAccess.value ? this.userLoggedAccess.value.refreshToken : null,
            })
            .pipe(
                map((responseData) => {
                    const expirationDate = new Date(new Date().getTime() + +responseData.accessTokenExpirationTime * 1000);
                    return { ...responseData, expirationDate };
                }),
                tap((user) => {
                    this.userLoggedAccess.next(user);
                    localStorage.setItem('userData', JSON.stringify(user));
                }),
                catchError((error) => {
                    this.userLoggedAccess.next(null);
                    localStorage.removeItem('userData');
                    this.router.navigate(['/login']);
                    throw error;
                })
            );
    }

    autoLogin(): void {
        const userData = localStorage.getItem('userData');
        if (!userData) {
            this.userLoggedDetails$.next(null);
            return;
        }

        const userTokens = JSON.parse(userData);
        if (!userTokens) {
            this.userLoggedDetails$.next(null);
            return;
        }

        this.userLoggedAccess.next(userTokens);

        this.accessTokenDetailsDecode = jwt_decode(userTokens.accessToken);

        this.getUserDetails()
            .pipe(
                catchError(() => {
                    this.router.navigate(['/login']);
                    localStorage.removeItem('userData');
                    return EMPTY;
                })
            )
            .subscribe();
    }

    @LoadingScope()
    getUserDetails(): Observable<UserDetails> {
        return this.http.get<UserDetails>(`/api/me`).pipe(
            catchError((errorRes) => {
                if (errorRes.status === HttpStatusCode.Forbidden) {
                    this.router.navigate(['/onboarding/setPassword']);
                }
                return throwError(() => errorRes);
            }),
            switchMap((resData: UserDetails) => this.handleLanguageChange(resData)),
            tap((resData: UserDetails) => {
                this.userLoggedDetails$.next(resData);
            })
        );
    }

    private handleLanguageChange(resData: UserDetails): Observable<UserDetails> {
        const localStorageLanguage = allTranslationLanguages.find((language) => language.translate === localStorage.getItem('language'));
        const preferredLanguage =
            localStorageLanguage?.translate !== resData.preferredLanguage && localStorageLanguage
                ? localStorageLanguage.translate
                : resData.preferredLanguage;
    
        this.translate.setDefaultLang(preferredLanguage);
        this.translate.use(preferredLanguage);
    
        if (localStorageLanguage && localStorageLanguage.translate !== resData.preferredLanguage) {
            return this.userService.changeLanguage(resData.employeeId, preferredLanguage).pipe(
                map(() => resData)
            );
        }
    
        return of(resData);
    }

    private redirectUserByRole(role: Role[]): void {
        if (role.includes(Role.HR) || role.includes(Role.TEAMLEAD)) {
            this.router.navigate(['/requests']);
        } else if (role.includes(Role.ADMIN)) {
            this.router.navigate(['/holidays']);
        } else if (role.includes(Role.USER)) {
            this.router.navigate(['/holidays']);
        }
    }
}
