import { Component, Input } from '@angular/core';
import { BannerContent } from '../../models';

@Component({
    selector: 'ado-core-notification-banner',
    templateUrl: './notification-banner.component.html',
    styleUrls: ['./notification-banner.component.scss'],
})
export class NotificationBannerComponent {
    @Input() bannerContent?: BannerContent;
    @Input() impersonationMode?: boolean;
}
