import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserDetails } from '@axon/data-access-lib';

@Injectable({providedIn: 'root'})
export class UserService {
    constructor(private http: HttpClient) {}

    public changeLanguage(userId: string, preferredLanguage: string) {
        return this.http.patch<UserDetails>(
            `api/users/${userId}/user-preferences`,
            { preferredLanguage }
        );
    }
}
