import { ChangeDetectorRef, Directive, HostListener, Input } from '@angular/core';
import { FilterNewItemComponent } from '../components';

@Directive({
    selector: '[adoCoreFilterNewItemFocus]',
})
export class FilterNewItemFocusDirective {
    @Input() componentRef?: FilterNewItemComponent;

    constructor(private cdr: ChangeDetectorRef) {}

    @HostListener('focusout', ['$event']) onBlur(event: FocusEvent) {
        setTimeout(() => {
            if (this.componentRef) {
                if (this.componentRef.dropdownFilter && !this.componentRef.dropdownFilter.dropdown?.isOpen()) {
                    this.componentRef.dropdownFilter.emitClearCurrentFilterEvent();
                } else if (this.componentRef.datepickerFilter && !this.componentRef.datepickerFilter.datepicker?.isOpen()) {
                    this.componentRef.datepickerFilter.emitClearCurrentFilterEvent();
                } else {
                    if (this.componentRef.dropdownFilter) {
                        this.componentRef.dropdownFilter.input?.nativeElement.focus();
                    } else if (this.componentRef.datepickerFilter) {
                        this.componentRef.datepickerFilter.input?.nativeElement.focus();
                    }
                    this.cdr.markForCheck();
                }
            }
        }, 100);
    }
}
