import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ENVIRONMENT, Environment } from '@axon-dot/fe/shared/environments';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PublicAppGuard{
    constructor(
        private router: Router,
        @Inject(ENVIRONMENT) private env: Environment
    ) {}

    canLoad():
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        if (this.env.appMode === 'PUBLIC') {
            this.router.navigate(['/holidays']);
            return false;
        }
        return true;
    }
}
