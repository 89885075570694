import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[adoCoreScrollSpy]'
})
export class ScrollSpyDirective {
    @Input() public spiedTags: string[] = [];
    @Output() public sectionChange = new EventEmitter<string>();
    private currentSection = '';

    constructor(private _el: ElementRef) {}

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {

        let section = '';
        const children = this._el.nativeElement.children;
        const element = event.target;

        const scrollTop = element.scrollTop;
        const parentOffset = element.offsetTop;

        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;


        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (this.spiedTags.some(spiedTag => spiedTag === child.tagName)) {
                if ((child.offsetTop - parentOffset) <= scrollTop + 10) {
                    section = child.id;
                }
            }
        }

         //the scroll is down
         if(scrollTop + clientHeight >= scrollHeight - 1){
            section = children[children.length - 1].id;
        }

        if (section !== this.currentSection) {
            this.currentSection = section;
            this.sectionChange.emit(this.currentSection);
        }
    }

}
