import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsService {
    private readonly FEATURE_FLAGS = 'feature-flags';
    private readonly FEATURE_FLAGS_URL = '/api/feature-flags';
    private featureFlags$ = new BehaviorSubject<Map<string, boolean> | null>(null);

    constructor(private http: HttpClient) {}

    loadConfig() {
        return this.getFeatureFlags().subscribe((response: { [key: string]: boolean }) => {
            const featureFlagsMap = new Map<string, boolean>(Object.entries(response[this.FEATURE_FLAGS]));
            this.featureFlags$.next(featureFlagsMap);
        });
    }

    private getFeatureFlags() {
        return this.http.get<{ [key: string]: boolean }>(this.FEATURE_FLAGS_URL);
    }

    isFeatureFlagEnabled$(key: string): Observable<boolean> {
        return this.featureFlags$.asObservable().pipe(
            filter((featureFlags) => 
                !!featureFlags
            ),
            map((featureFlags) => featureFlags as Map<string, boolean>),
            map((featureFlags) => featureFlags.get(key)),
            map((flag) => !!flag)
        );
    }
}
