import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { MiscService, OptionSelect, OptionType } from '@axon/data-access-lib';
import { Observable, map } from 'rxjs';

export const calendarResolver: ResolveFn<OptionSelect[]> = (): Observable<OptionSelect[]> => {
    const miscService = inject(MiscService);

    return miscService.getOptionsSelect(OptionType.CALENDARS).pipe(
        map<string[], OptionSelect[]>(calendars => {
            return calendars.map(calendar => {
                return {
                    value: calendar,
                    label: `lovs.calendars.${calendar}`,
                };
            });
        })
    );
};
