<div
    class="input-group form-floating form-group"
    [ngClass]="{ invalid: dateControl.invalid }">
    <input
        adoCoreFocusDatepickerClass
        tabindex="-1"
        autocomplete="off"
        type="text"
        class="form-control datepicker"
        ngbDatepicker
        #d1="ngbDatepicker"
        [formControl]="dateControl"
        [placeholder]="title | translate"
        id="date"
        [minDate]="minDate"
        [maxDate]="maxDate" />
    <label for="date" class="form-label">{{ title | translate }} </label>
    <button
        adoCoreFocusDatepickerClass
        class="btn button-datepicker"
        [disabled]="dateControl.disabled"
        (click)="d1.toggle()"
        type="button">
        <i class="fa-solid fa-calendar-days text-body"></i>
    </button>
</div>
<span class="text-danger" *ngIf="dateControl.hasError('invalidCharacters') && dateControl.touched">
    {{ 'errors.invalid-characters' | translate }}
</span>