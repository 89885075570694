export interface OptionSelect {
  value: string;
  label: string;
}

export enum OptionType {
  CALENDARS,
  EMPLOYEE_STATUS,
  LEAVE_REQUEST_TYPES,
  USER_ROLES,
  LEAVE_REQUEST_STATUS,
  OPERATION_TYPES,
  SKILLS,
  SKILL_LEVELS,
  EDUCATION_TYPES,
  JOB_TITLES
}
