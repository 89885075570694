<span class="selected-option"
      *ngIf="!isOpen"
      (click)="startEditing(filterField!)">
  <!-- When a text filter is set as initial filter and we want to display ALL in the value box -->
  {{ filterField }}
  <button type="
        button"
          class="btn delete"
          (click)="onDeleteFilter()">
    <i class="fa fa-times d-flex justify-content-center align-items-center"></i>
  </button>
</span>
<ado-core-filter-text-field *ngIf="isOpen"
                            #textFilter
                            [initialValue]="filterField"
                            (valueChanged)="onTextChanged($event)"
                            (clearFilter)="onDeleteFilter()">
</ado-core-filter-text-field>