<div class="py-5">
    <div class="row"
         *ngFor="let item of items; let i = index; trackBy: identifyItemById"
         [ngClass]="timelinePosition(linePosition.position, i)">
        <div class="col-2 mt-3 d-none d-sm-block"
             *ngIf="linePosition.position === 'start' && showDate">
            <div class="row text-gray-700">{{ item.date }}</div>
            <div class="row fw-bold fs-4">{{ item.time }}</div>
        </div>
        <div class="col-1 d-flex justify-content-start"
             *ngIf="linePosition.position === 'start' || linePosition.position === 'center' && i % 2 !== 0">
            <div class="line-container">
                <div class="vertical-line"
                     [ngClass]="item.bgColor">
                    <span class="centered-span"
                          [ngClass]="item.bgColor"><i class="fas text-white"
                           [ngClass]="item.iconClass"></i></span>
                </div>
            </div>
        </div>

        <div class="col-11">
            <div class="timeline-item mb-5 col-12">
                <ng-container *ngTemplateOutlet="templateRef!; context: {event: item}">
                </ng-container>
            </div>
        </div>

        <div class="col-1 d-flex justify-content-start"
             *ngIf="linePosition.position === 'end' || linePosition.position ==='center' && i % 2 === 0">
            <div class="line-container">
                <div class="vertical-line"
                     [ngClass]="item.bgColor">
                    <span class="centered-span"
                          [ngClass]="item.bgColor"><i class="fas text-white"
                           [ngClass]="item.iconClass"></i></span>
                </div>
            </div>
        </div>
        <div class="col-2 mt-3 d-none d-sm-block"
             *ngIf="linePosition.position === 'end' && showDate">
            <div class="row text-gray-700">{{ item.date | date : dateFormat }}</div>
            <div class="row fw-bold fs-4">{{ item.time }}</div>
        </div>
    </div>
</div>