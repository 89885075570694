import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { PIPES } from './pipes';

@NgModule({
    imports: [CommonModule, HttpClientModule],
    declarations: [...PIPES],
    exports: [...PIPES],
})
export class DataAccessLibModule {}
