import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import {
    AbstractControl,
    AbstractControlDirective,
    ControlContainer,
    FormGroupDirective,
    FormGroupName,
} from '@angular/forms';
import { startWith } from 'rxjs/operators';
import {
    ErrorMessageComponent,
    ValidationErrorResult,
} from '../components/error-message/error-message.component';

@Directive({
    selector: '[adoCoreValidationError]',
})
export class NgErrorDirective extends AbstractControlDirective implements OnInit {
    @Input('adoCoreValidationError') controlName!: string;
    @Input() errorKeys!: { [key: string]: ValidationErrorResult };
    @Input() validationErrorBackgroundColor = 'var(--bs-body-bg)';
    errorComponent?: ErrorMessageComponent;
    maxNumber = 30;

    constructor(
        private controlContainer: ControlContainer,
        private viewContainerRef: ViewContainerRef,
    ) {
        super();
    }

    get control(): AbstractControl | null {
        if (this.controlContainer instanceof FormGroupDirective) {
            return this.controlContainer.form.get(this.controlName);
        } else if (this.controlContainer instanceof FormGroupName) {
            return this.controlContainer.control.get(this.controlName);
        }
        return this.controlContainer.control;
    }

    get errorMessages(): ValidationErrorResult[] {
        if (!(this.control && this.control.errors)) {
            return [];
        }

        const err = Object.keys(this.control.errors).map(key => {
            return this.errorKeys[key];
        });

        return err;
    }

    private getErrorMessage(key: string, contextVariables?: unknown): ValidationErrorResult[] {
        switch (key) {
            case 'required':
                return [{ key: 'errors.required', params: contextVariables }];
            case 'minlength':
                return [{ key: 'errors.minLength', params: contextVariables }];
            case 'maxlength':
                return [{ key: 'errors.maxLength', params: contextVariables }];
            case 'email':
                return [{ key: 'errors.email', params: contextVariables }];
            case 'pattern':
                return [{ key: 'errors.pattern', params: contextVariables }];
            case 'min':
                return [{ key: 'errors.min', params: contextVariables }];
            case 'max':
                return [{ key: 'errors.max', params: contextVariables }];

            case 'invalid':
                return [{ key: 'errors.invalid', params: contextVariables }];
            default:
                return [{ key: `errors.${key}`, params: contextVariables }];
        }
    }

    ngOnInit(): void {
        this.errorComponent = this.viewContainerRef.createComponent(ErrorMessageComponent).instance;

        if (!this.control || !this.errorComponent) {
            return;
        }

        this.errorComponent.control = this.control;
        this.errorComponent.validationErrorBackgroundColor = this.validationErrorBackgroundColor;

        // This workaround had to be added due to an angular bug.
        // statusChanges won't change from `PENDING` status, even if the AsyncValidator returns with an error after X time
        // Workaround was inspired by: https://stackoverflow.com/questions/58817647/form-stuck-in-pending-status-with-async-validator-when-value-changed-on-construc
        // Ongoing angular github task: https://github.com/angular/angular/issues/41519
        let firstPending = true;

        this.control.statusChanges.pipe(startWith(this.control.status)).subscribe(status => {
            if (firstPending && status === 'PENDING') {
                firstPending = false;
                setTimeout(() => {
                    if (!this.control) {
                        return;
                    }

                    this.control.updateValueAndValidity();
                    this.control.markAsTouched();
                    this.control.markAsDirty();
                });
            }

            if (!this.errorComponent) {
                return;
            }

            if (this.errorKeys) {
                this.errorComponent.errorMessages = this.errorMessages;
                return;
            }

            if (this.control && this.control.errors) {
                const err = Object.keys(this.control.errors).map(key => {
                    return key;
                });

                this.errorComponent.errorMessages = this.getErrorMessage(
                    err[0].toString(),
                    this.control.errors[err[0]]
                );
            }
        });
    }
}
