<div ngbDropdown
     class="form-floating"
     (keydown)="onKeyDown($event)"
     (openChange)="onOpen($event)"
     [autoClose]="'outside'"
     tabindex="0">
    <div ngbDropdownToggle
         [ngClass]="{ disabled: isDisabled, 'my-dropdown-open': isOpen }"
         class="form-control d-flex align-items-center multi-select-container"
         id="floatingSelect">
        <ng-container *ngIf="selectedOptions.length > 0">
            <div *ngFor="let option of selectedOptions; trackBy:identifyOptionByValue; let i = index"
                 class="selected-option">
                <img *ngIf="option.srcLeft"
                     [src]="option.srcLeft"
                     width="20px"
                     height="20px"
                     class="me-2 rounded-circle" />
                {{ option.label }}
                <button type="button"
                        class="btn btn-outline-secondary btn-sm"
                        (click)="removeOption($event, option)">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </ng-container>
    </div>

    <div ngbDropdownMenu
         class="my-dropdown-menu-open">
        <div class="input-group mb-2">
            <input [formControl]="searchOptionControl"
                   class="form-control example search-input"
                   [placeholder]="'entity.typeToSearch' | translate" />
            <button *ngIf="allowCustomOptions"
                    [disabled]="isAddNewOptionDisabled"
                    (click)="onAddNewOption()"
                    class="btn btn-outline-gray"
                    type="button"
                    id="button-addon2">
                <i class="fa-solid fa-plus me-3"></i>Add option</button>
        </div>
        <div class="scrollable-class">
            <div ngbDropdownItem
                 #item
                 *ngFor="let option of filteredOptions; let i = index; trackBy:identifyOptionByValue"
                 [ngClass]="{'active-option': i === selectedIndex}"
                 class="option"
                 (click)="onSelectedOption(option)">
                <img *ngIf="option.srcLeft"
                     [src]="option.srcLeft"
                     width="20px"
                     height="20px"
                     class="me-1 rounded-circle" />
                {{ option.label }}
            </div>
            <div *ngIf="filteredOptions.length === 0"
                 class="ms-3">
                {{ 'errors.noResults' | translate }} "{{ searchOptionControl.value }}"
            </div>
        </div>
    </div>
    <label for="floatingSelect">{{ placeholder }}</label>
</div>