import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule} from "@ngx-translate/core";
import { ToastsContainerComponent } from './components/toasts-container.component';

@NgModule({
    imports: [CommonModule, NgbToastModule, BrowserModule, TranslateModule],
    declarations: [ToastsContainerComponent],
    exports: [CommonModule, ToastsContainerComponent],
})
export class ToastNotificationsModule {}
