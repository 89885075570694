<!-- This flex container takes the full height -->

<div class="d-flex flex-column min-vh-100  text-body bg-body" [attr.data-bs-theme]="darkMode">
    <ado-core-header></ado-core-header>
    <!-- The main area does not shrink, 'pushing down' the footer -->
    <main class="flex-shrink-0 position-relative">
        <!-- This will render the routes -->
        <div class="container-md my-3 mt-md-5">
            <router-outlet></router-outlet>
        </div>
    </main>
    <!-- This keeps the footer down if the main content does not fill up the space -->
    <footer class="mt-auto">
        <ado-core-footer></ado-core-footer>
    </footer>
</div>

<ng-template #modalTemplate>
    <div class="d-flex justify-content-center mb-3">
        <i class="fa-solid fa-triangle-exclamation fa-5x text-warning"></i>
    </div>
    <span>
        {{ 'features.leave-schedules.uninitialized-modal.body' | translate }}
    </span>
</ng-template>
