import { Calendar } from "./calendar.model";

export interface Credentials {
    password: string;
    username: string;
}

export enum Role {
    USER = 'USER',
    HR = 'HR',
    TEAMLEAD = 'TEAM_LEADER',
    ADMIN = 'ADMIN',
}

export interface UserDetails {
    calendar: Calendar;
    employeeId: string;
    is2FaMandatory: boolean;
    isAutogeneratedPassword: boolean;
    permissions: string[];
    roles: Role[];
    teamDetails: TeamDetailsUser;
    username: string;
    preferredLanguage: string;
    name: string;
    surname: string;
}

export interface TeamDetailsUser {
    id: number;
    name: string;
}

export interface UserLoginResponse {
    accessToken: string;
    accessTokenExpirationTime: string;
    refreshToken: string;
    refreshTokenExpirationTime: string;
}

