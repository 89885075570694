import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterModule, Routes, UrlSegment } from '@angular/router';
import { FeatureFlagsGuard } from '@axon/axon-lib';
import { Role } from '@axon/data-access-lib';
import { LayoutComponent, LoginGuard, OnboardingGuard, PublicAppGuard } from './core';
import { MaintenanceGuard } from './features/maintenance';

const routes: Routes = [
    { path: '', redirectTo: 'holidays', pathMatch: 'full' },
    {
        path: '',
        component: LayoutComponent,
        canActivateChild: [
            (route: ActivatedRouteSnapshot) => inject(LoginGuard).canActivateChild(route),
            () => inject(MaintenanceGuard).canActivateChild(),
            OnboardingGuard(),
        ],
        children: [
            {
                path: 'cv',
                data: { featureFlag: 'cv', fallback: '/fallback-page' },
                loadChildren: () => import('@axon/cv').then((m) => m.AxonCvLibModule),
                canLoad: [(route: Route, segments: UrlSegment[]) => inject(FeatureFlagsGuard).canLoad(route, segments)],
            },
            {
                path: 'fallback-page',
                loadChildren: () => import('./features/feature-disabled-fallback/feature-disabled-fallback.module').then((m) => m.FeatureDisabledFallbackModule),
            },
            {
                path: 'employees',
                data: { roles: [Role.HR], featureFlag: 'employees', fallback: '/fallback-page' },
                loadChildren: () => import('./features/employees/employees.module').then((m) => m.EmployeesModule),
                canLoad: [
                    () => inject(PublicAppGuard).canLoad(),
                    (route: Route, segments: UrlSegment[]) => inject(FeatureFlagsGuard).canLoad(route, segments),
                ],
            },
            {
                path: 'admin',
                data: { roles: [Role.ADMIN] },
                loadChildren: () => import('./features/admin-management/admin-management.module').then((m) => m.AdminManagementModule),
            },
            {
                path: 'assign-holidays',
                data: {
                    roles: [Role.HR],
                    featureFlag: 'assign-holidays',
                    fallback: '/fallback-page',
                },
                loadChildren: () => import('./features/assign-holidays/assign-holidays.module').then((m) => m.AssignHolidaysModule),
                canLoad: [
                    () => inject(PublicAppGuard).canLoad(),
                    (route: Route, segments: UrlSegment[]) => inject(FeatureFlagsGuard).canLoad(route, segments),
                ],
            },
            {
                path: 'holidays',
                canLoad: [(route: Route, segments: UrlSegment[]) => inject(FeatureFlagsGuard).canLoad(route, segments)],
                data: { featureFlag: 'holidays', fallback: '/fallback-page' },
                loadChildren: () =>
                    import('./features/individual-holidays/individual-holidays.module').then((m) => m.IndividualHolidaysModule),
            },
            {
                path: 'planned-holidays',
                canLoad: [(route: Route, segments: UrlSegment[]) => inject(FeatureFlagsGuard).canLoad(route, segments)],
                data: { featureFlag: 'planned-holidays', fallback: '/fallback-page' },
                loadChildren: () =>
                    import('./features/planned-holidays-for-all-teams/planned-holidays-for-all-teams.module').then(
                        (m) => m.PlannedHolidaysForAllTeamsModule
                    ),
            },
            {
                path: 'requests',
                data: {
                    roles: [Role.HR, Role.TEAMLEAD],
                    featureFlag: 'leave-requests',
                    fallback: '/fallback-page',
                },
                loadChildren: () => import('./features/leave-requests/leave-requests.module').then((m) => m.LeaveRequestsModule),
                canLoad: [
                    () => inject(PublicAppGuard).canLoad(),
                    (route: Route, segments: UrlSegment[]) => inject(FeatureFlagsGuard).canLoad(route, segments),
                ],
            },
            {
                path: 'profile',
                loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule),
            },
            {
                path: 'teams',
                data: {
                    roles: [Role.HR, Role.TEAMLEAD],
                    featureFlag: 'teams',
                    fallback: '/fallback-page',
                },
                loadChildren: () => import('./features/teams-management/teams-management.module').then((m) => m.TeamsManagementModule),
                canLoad: [
                    () => inject(PublicAppGuard).canLoad(),
                    (route: Route, segments: UrlSegment[]) => inject(FeatureFlagsGuard).canLoad(route, segments),
                ],
            },
        ],
    },
    {
        path: 'demo',
        loadChildren: () => import('./features/demo/demo.module').then((m) => m.DemoModule),
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./features/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () =>
            import('./features/forgot-and-confirm-password/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    },
    {
        path: 'users/password/edit',
        loadChildren: () =>
            import('./features/forgot-and-confirm-password/confirm-password/confirm-password.module').then((m) => m.ConfirmPasswordModule),
    },
    {
        path: 'users/two-factor-authentication/deactivate',
        loadChildren: () => import('./features/two-fa-deactivation/two-fa-deactivation.module').then((m) => m.TwoFactorDeactivationModule),
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./features/onboarding/onboarding.module').then((m) => m.OnboardingModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: '**',
        redirectTo: 'requests',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
