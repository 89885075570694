import { Language } from "../models";

export const allTranslationLanguages: Language[] = [
    {
        text: 'entity.en',
        translate: 'en',
    },
    {
        text: 'entity.ro',
        translate: 'ro',
    },
];
