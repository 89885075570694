import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Constants } from '../../utils';

export interface ValidationErrorResult {
    key: string,
    params?: any
}

@Component({
    selector: 'ado-core-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss'],
    //TODO: needs further implementation, we need to listen to control changes and run the change detection manually
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorMessageComponent {
    @Input() errorMessages?: ValidationErrorResult[];
    @Input() control?: AbstractControl;
    @Input() validationErrorBackgroundColor =
        Constants.DEFAULT_BACKGROUND_COLOR;

     identifyErrorByKey(index: number, error: any) {
        return error.key;
    }
}
