<ng-container *ngIf="!!filterField && options && placeholder">
  <span class="selected-option" *ngIf="!isOpen" (click)="startEditing(filterField)">
    <img *ngIf="filterField.srcLeft" [src]="filterField.srcLeft" width="20px" height="20px"
      class="me-1 rounded-circle" />
    {{filterField.label | translate}}
    <button type="button" *ngIf="deletable" class="btn delete" (click)="onDeleteFilter()">
      <i class="fa fa-times d-flex justify-content-center align-items-center"></i>
    </button>
  </span>
  <ado-core-filter-dropdown-field *ngIf="isOpen" adoCoreFilterDropdownFocus #dropdownField
    [componentRef]="dropdownField" (stopEditing)="stopEditing($event)" [options]="options" [initialValue]="filterField"
    [placeholder]="placeholder" (optionSelected)="onSelectChange($event)">
  </ado-core-filter-dropdown-field>
</ng-container>