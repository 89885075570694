import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FilterNgbDropdownOption } from '../../../models';

@Component({
    selector: 'ado-core-filter-edit-item-label-dropdown',
    templateUrl: './filter-edit-item-label-dropdown.component.html',
    styleUrls: ['./filter-edit-item-label-dropdown.component.scss'],
})
export class FilterEditItemLabelComponent implements OnChanges {
    @Input() filterField?: FilterNgbDropdownOption;
    @Input() options?: FilterNgbDropdownOption[];
    @Input() placeholder?: string;
    @Input() deletable = false;
    @Input() open = false;
    @Output() selectChange = new EventEmitter<FilterNgbDropdownOption>();
    @Output() filterDeleted = new EventEmitter<boolean>();
    isOpen = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes['open'] && changes['open'].previousValue !== changes['open'].currentValue) {
            this.isOpen = this.open;
        } else {
            this.isOpen = false;
        }
    }

    /** Method used when we start to edit a field from a filter (when we click on a selected option) */
    startEditing(value: FilterNgbDropdownOption): void {
        this.isOpen = true;
    }

    /** Method used when we stop editing a field from a filter (when we click outside or press ENTER key or another option) */
    stopEditing(value: FilterNgbDropdownOption) {
        if(!value){
            this.onDeleteFilter();
        }
        this.isOpen = false;
    }

    onSelectChange(option: FilterNgbDropdownOption) {
        this.isOpen = false;
        this.selectChange.emit(option);
    }

    onDeleteFilter() {
        if (this.deletable) {
            this.filterDeleted.emit(true);
            this.isOpen = false;
        }
    }
}
