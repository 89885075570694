import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import { NgbDropdownOption } from '../../models/ngb-dropdown-option.model';

/**
 * Custom component for a multi-select dropdown.
 * It allows users to select multiple options from a dropdown list.
 */
@Component({
    selector: 'ado-core-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MultiSelectComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent implements OnChanges, ControlValueAccessor, Validator, OnInit, OnDestroy {
    @ViewChildren('item') dropdownItems?: QueryList<ElementRef>;
    @Input() options: NgbDropdownOption[] = [];
    @Input() placeholder = '';
    @Input() allowCustomOptions = false;

    readonly searchOptionControl = new FormControl<string>('');

    allOptions: NgbDropdownOption[] = [];
    selectedOptions: NgbDropdownOption[] = [];
    remainingOptions: NgbDropdownOption[] = [];
    filteredOptions: NgbDropdownOption[] = [];

    isDisabled = false;
    isOpen = false;
    isAddNewOptionDisabled = true;
    selectedIndex = -1;

    private writtenValues: (string | number)[] = [];
    private _onChange?: (arg: (string | number)[] | null) => void;
    private _onTouch?: () => void;
    private _onValidation?: () => void;
    private subscriptions = new Subscription();

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.searchOptionControl.valueChanges.pipe(debounceTime(250)).subscribe((searchText) => {
                this.filterOptions(searchText || '');
                this.checkAddNewOptionDisabled();
                if (searchText === '') {
                    this.isAddNewOptionDisabled = true;
                }
                this.cdr.detectChanges();
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && this.options) {
            this.allOptions = [...this.options];
            this.selectedOptions = this.allOptions.filter((option) => this.writtenValues.includes(option.value));
            this.remainingOptions = this.allOptions.filter((option) => !this.selectedOptions.includes(option));
            this.filterOptions(this.searchOptionControl.value || '');
            this.cdr.markForCheck();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Identifies an option by its value
     * @param index Index of the option in the list
     * @param option NgbDropdownOption to identify
     * @returns The value of the option
     */
    identifyOptionByValue(index: number, option: NgbDropdownOption) {
        return option.value;
    }

    /**
     * Writes values to the component
     * @param value Array of string or number values to set as selected
     */
    writeValue(value: (string | number)[]): void {
        this.selectedOptions = this.allOptions.filter((option) => value.includes(option.value));
        this.writtenValues = [...this.selectedOptions].map((option) => option.value);
        this.remainingOptions = this.allOptions.filter((option) => !this.selectedOptions.includes(option));
        this.filterOptions(this.searchOptionControl.value || '');
        this.cdr.markForCheck();
    }

    registerOnChange(fn: () => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouch = fn;
    }

    /**
     * Sets the disabled state of the component
     * @param isDisabled Boolean flag to set the disabled state
     */
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidation = fn;
    }

    validate(): ValidationErrors | null {
        return null;
    }

    /**
     * Handles selection of an option
     * @param option NgbDropdownOption selected by the user
     */
    onSelectedOption(option: NgbDropdownOption): void {
        this.selectedOptions.unshift(option);
        this.writtenValues.push(option.value);
        this.remainingOptions = this.remainingOptions.filter((item) => item !== option);
        this.filterOptions(this.searchOptionControl.value || '');

        if (this._onChange && this._onTouch && this._onValidation) {
            this._onChange(this.writtenValues);
            this._onTouch();
            //  this._onValidation();
        }
        this.cdr.markForCheck();

        if (!this.filteredOptions.length) {
            this.searchOptionControl.reset();
        }
    }

    /**
     * Removes an option from the selection
     * @param event MouseEvent triggered on option removal
     * @param option NgbDropdownOption to be removed
     */
    removeOption(event: MouseEvent, option: NgbDropdownOption): void {
        event.stopPropagation();

        this.selectedOptions = this.selectedOptions.filter((selected) => selected.label !== option.label);
        this.writtenValues = [...this.selectedOptions].map((option) => option.value);
        this.remainingOptions.push(option);
        this.filterOptions(this.searchOptionControl.value || '');

        if (this._onChange && this._onTouch && this._onValidation) {
            this._onChange(this.writtenValues);
            this._onTouch();
            //  this._onValidation();
        }

        this.cdr.markForCheck();
    }

    /**
     * Handles addition of a new option
     */
    onAddNewOption(): void {
        const newValue = this.searchOptionControl.value;
        if (newValue) {
            const newOption = {
                value: newValue,
                label: newValue,
            };
            this.allOptions.push(newOption);
            this.selectedOptions.unshift(newOption);
            this.writtenValues.push(newOption.label);
            this.isAddNewOptionDisabled = false;
            this.checkAddNewOptionDisabled();
        }
    }

    /**
     * Handles the opening/closing of the dropdown
     * @param isOpen Boolean flag indicating whether the dropdown is open
     */
    onOpen(isOpen: boolean) {
        this.isOpen = isOpen;
        if (this._onTouch) {
            this._onTouch();
        }
    }

    /**
     * Handles keyboard events for navigation and selection
     * @param event KeyboardEvent triggered by key press
     */
    onKeyDown(event: KeyboardEvent) {
        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                if (this.selectedIndex > 0) {
                    this.selectedIndex--;
                    this.dropdownItems?.get(this.selectedIndex)?.nativeElement.scrollIntoView(true);
                }
                break;
            case 'ArrowDown':
                event.preventDefault();
                if (this.selectedIndex < this.filteredOptions.length - 1) {
                    this.selectedIndex++;
                    this.dropdownItems?.get(this.selectedIndex)?.nativeElement.scrollIntoView(true);
                }
                break;
            case 'Enter':
                event.preventDefault();
                if (this.selectedIndex !== -1 && this.filteredOptions.length > 0) {
                    this.onSelectedOption(this.filteredOptions[this.selectedIndex]);
                }
                break;
            default:
                break;
        }
    }

    /**
     * Checks whether adding a new option is disabled
     */
    private checkAddNewOptionDisabled(): void {
        this.isAddNewOptionDisabled = this.allOptions.some(
            (option) => option.label.toLowerCase() === this.searchOptionControl.value?.toLowerCase()
        );
    }

    /**
     * Filters the options based on the provided search text
     * @param searchText Text used for filtering options
     */
    private filterOptions(searchText: string): void {
        this.filteredOptions = this.remainingOptions.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));
    }
}
