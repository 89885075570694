<div class="strength-meter"
     [attr.data-strength-score]="score">
    <div class="strength-text">{{ strengthText | translate }}</div>
    <div class="progress strength-wrapper">
        <div class="progress-bar"
             role="progressbar"
             [ngClass]="{
                'bg-danger': score < 25,
                'bg-warning': score >= 25 && score < 50,
                'bg-tertiary': score >= 50 && score < 75,
                'bg-success': score >= 75
            }"
             [style.width.%]="score"
             aria-valuemin="0"
             aria-valuemax="100"></div>
    </div>
    <div>{{ 'features.change-password.strength-meter.password-contain' | translate }}</div>
    <div class="strength-feedback" *ngFor="let item of feedbackArr; trackBy:identifyFeedbackByLabel">
        {{ item.label | translate : item.params}}
        <i class="fa"
           [ngClass]="
                item.status ? 'fa-check text-success' : 'fa-times text-danger'
            "></i>
    </div>
</div>