// TODO: Find a more generic solution
export class Constants {
    static readonly WEEKEND_DAYS_COLOR = '#808080';
    static readonly PLANNING_FREE_DAYS_COLOR = '#ce9fa2';
    static readonly FREE_DAYS_BACKGROUND_COLOR = '#0000FF';
    static readonly HOLIDAYS_COLOR = '#89133d';
    static readonly FREE_DAYS_COLOR_TEXT = '#FFFFFF';
    static readonly REQUEST_APPROVED_COLOR = '#008A3F';
    static readonly REQUEST_PENDING_COLOR = '#0000FF';
    static readonly REQUEST_REJECTED_COLOR = '#FF0000';
    static readonly REQUEST_UNKNOWN_COLOR = '#808080';
    static readonly REQUEST_UNKNOWN_STATUS_LABEL = 'Unknown';
    static readonly TODAY_TEXT = 'features.my-holidays.today';
    static readonly DATE_PIPE_LOCALE = 'en-US';
    static readonly FORMAT_STRING_DATE = 'yyyy-MM-dd';
    static readonly DATE_MONTH_YEAR = "MMM yyyy";
    static readonly DATE_FORMAT_DISPLAY = 'dd/MM/yyyy';
    static readonly DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
    static readonly DATE_TIME_FORMAT_DISPLAY = 'dd/MM/yyyy HH:mm';
    static readonly UNAVAILABLE_DAYS_COLOR = '#D0D0D0';
    static readonly LOGIN_BACKGROUND_COLOR = '#f0f2f5';
    static readonly EMPLOYEE_DETAILS_BACKGROUND_COLOR = '#f6f8fc';
    static readonly DEFAULT_BACKGROUND_COLOR = '#fff';
    static readonly VERY_OLD_DATE = '0000-01-01';
    static readonly WHITE_COLOR = '#fff';
    static readonly SPECIAL_CHARACTERS = /[!@#$%*-]/;
}
