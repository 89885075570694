export enum TypeRequest {
    //TODO: Check if these values in Romanian are still useful or not.
    ALL = 'Toate cererile',
    HOLIDAY = 'Vacanta',
    MEDICAL_LEAVE = 'Medical',
}

export enum TypeFilter {
    ALL = 'ALL',
    MEDICAL_LEAVE = 'MEDICAL_LEAVE',
    HOLIDAY = 'HOLIDAY',
}