import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { LoginService } from '@axon/data-access-lib';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Role } from '@axon/data-access-lib';

@Injectable({ providedIn: 'root' })
export class LoginGuard {
    constructor(private loginService: LoginService, private router: Router) {}

    canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.loginService.userLoggedDetailsPossiblyNull.pipe(
            take(1),
            map((user) => {
                if (!user) {
                    this.router.navigate(['/login']);
                    return false;
                }

                const userRoles = user.roles;

                if (!childRoute.data.roles) {
                    return true;
                }

                let redirect = true;
                for (let i = 0; i < userRoles.length; i++) {
                    if (childRoute.data.roles.indexOf(userRoles[i]) !== -1) {
                        redirect = false;
                    }
                }
                
                if (redirect == true) {
                    if (userRoles.includes(Role.HR) || userRoles.includes(Role.TEAMLEAD)) {
                        this.router.navigate(['/requests']);
                    } else if (userRoles.includes(Role.USER)) {
                        this.router.navigate(['/holidays']);
                    }

                    return false;
                }

                return true;
            })
        );
    }
}
