<div class="d-flex justify-content-center align-items-center vh-100">
    <div class="row">
        <div class="col-12 col-sm-9 card shadow mx-auto">
            <div class="card-body text-center">
                <i class="fa-solid fa-lock fa-2xl mb-5"></i>
                <h3 class="card-title">{{ 'login.2fa.title' | translate }}</h3>
                <div class="card-text">
                    <p class="mb-3">{{ 'login.2fa.description' | translate }}</p>
                    <ado-core-otp-input class="mb-3 justify-content-center" #code="ngModel" [(ngModel)]="data"></ado-core-otp-input>
                    <p *ngIf="invalidCode" class="text-danger">{{ 'login.2fa.invalid-code' | translate }}</p>
                </div>
                <p class="hr mb-3">{{ 'login.2fa.contact-hr' | translate }}</p>
            </div>
        </div>
    </div>
</div>
