<div class="overlay"
     [ngClass]="{
    'active': isOpen && hasBackdrop,
    'inactive': !isOpen || !hasBackdrop,
    'side': mode === 'side',
    'over': mode === 'over'}"
     (click)="close()">
</div>

<div class="drawer-container"
     [@enterLeaveRightLeft]="position"
     *ngIf="isOpen"
     [ngClass]="{
    'is-open': isOpen,
    'position-right': position === 'right',
    'position-left': position === 'left',
    'side': mode === 'side',
    'over': mode === 'over'
  }">
  <div class="header">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="body">
    <!-- to lazy load -->
    <ng-container *ngIf="drawerBodyRef; else eagerBody">
      <ng-container *ngTemplateOutlet="drawerBodyRef"></ng-container>
    </ng-container>
    <!-- to eager load -->
    <ng-template #eagerBody>
      <ng-content select="[body]"></ng-content>
    </ng-template>
  </div>
</div>