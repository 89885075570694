import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingScope } from '../decorators';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddedEmployeeDto, EmployeeActivationStatus, EmployeeCreationResponse, EmployeeInfoListResponse } from '../models';
import { EmployeeDetails } from '../models/employee-details.model';
import { EmployeeFilter } from '../models/employee-filter.model';
import { EmployeesList } from '../models/employees-list.model';
import { UpdateEmployeeDto } from '../models/update-employee-dto.model';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
    private filters?: EmployeeFilter;
    private employeesListSubject = new Subject<EmployeesList[]>();
    readonly employeesListObservable = this.employeesListSubject.asObservable();

    constructor(private http: HttpClient) {}

    loadEmployeesList(filters?: EmployeeFilter): Observable<EmployeesList[]> {
        if (filters) {
            this.filters = filters;
        }

        //TODO: encountered async problem when trying to unit test this function
        //GET response gets fetched before we returned the employees list observable
        this.getEmployees(this.filters).subscribe();
        return this.employeesListObservable;
    }

    @LoadingScope()
    public getEmployees(filters?: EmployeeFilter): Observable<EmployeesList[]> {
        let params = new HttpParams();
        if (filters) {
            this.filters = filters;
            if (this.filters.team && this.filters.team !== 'ALL') {
                params = params.append('teamId', this.filters.team);
            }
            if (this.filters.status && (this.filters.status as any) !== 'ALL') {
                params = params.append('status', this.filters.status);
            }
            //If someday we will want to add search field as an initial filter and we don't want to search by the "ALL" keyword (only display whole employees list when "ALL" keyword is in the search box)
            if (this.filters.search && this.filters.search !== 'entity.all') {
                params = params.append('search', this.filters.search);
            }
        }
        return this.http.get<{ items: EmployeesList[] }>(`api/employees`, { params }).pipe(
            map(responseData => {
                this.employeesListSubject.next(responseData.items);
                return responseData.items;
            })
        );
    }

    @LoadingScope()
    public addEmployee(employee: AddedEmployeeDto): Observable<EmployeeCreationResponse> {
        return this.http.post<EmployeeCreationResponse>(`/api/employees`, employee);
    }

    @LoadingScope()
    public updateEmployee(
        employeeId: string | undefined,
        updateEmployeeDto: UpdateEmployeeDto
    ): Observable<void> {
        return this.http.put<void>(`api/employees/${employeeId}`, {
            ...updateEmployeeDto,
        });
    }

    @LoadingScope()
    public getEmployeeDetails(employeeId: string): Observable<EmployeeDetails> {
        return this.http.get<EmployeeDetails>(`api/employees/${employeeId}`)
    }

    @LoadingScope()
    public setEmployeeStatus(
        employeeId: string,
        action: EmployeeActivationStatus
    ): Observable<void> {
        return this.http.post<void>(`api/employees/${employeeId}/status`, {
            action: action,
        });
    }

    @LoadingScope()
    public suspendEmployee(
        employeeId: string,
        suspensionReason: string
    ): Observable<void> {
        return this.http.post<void>(`api/employees/${employeeId}/status`, {
            action: EmployeeActivationStatus.SUSPEND,
            suspensionReason: suspensionReason
        });
    }

    @LoadingScope()
    public fetchEmployeesInfo(employeeIds: number[]): Observable<EmployeeInfoListResponse[]> {
        let employeeIdsParam = new HttpParams();
        employeeIdsParam = employeeIdsParam.append('employee-ids', employeeIds.join(', '));

        return this.http
            .get<{ items: EmployeeInfoListResponse[] }>(`api/employees/team-info`, {
                params: employeeIdsParam,
            })
            .pipe(
                map(response => {
                    return response.items;
                })
            );
    }

}
