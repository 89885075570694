import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * ProgressBarComponent is a reusable Angular component for displaying a progress bar with circles.
 *
 * @example
 * <ado-core-progress-bar
 *   [currentValue]="yourCurrentValue"
 *   [levels]="['Low', 'Medium', 'High']"
 *   [customStyles]="{ '--filled-circle-color': 'blue', '--empty-circle-color': 'lightblue' }"
 * ></ado-core-progress-bar>
 * @export
 * @class ProgressBarComponent
 */
@Component({
    selector: 'ado-core-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
    @Input() currentValue = 1;
    @Input() levels: string[] = [];
    @Input() customStyles: any = {};
}
