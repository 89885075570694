import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FilterNgbDropdownOption } from '../../models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ado-core-filter-dropdown-field',
    templateUrl: './filter-dropdown-field.component.html',
    styleUrls: ['./filter-dropdown-field.component.scss'],
})
export class FilterDropdownFieldComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('myDropdown', { static: false, read: NgbDropdown }) dropdown?: NgbDropdown;
    @ViewChild('input') input?: ElementRef;

    @Input() options: FilterNgbDropdownOption[] = [];
    @Input() initialValue?: FilterNgbDropdownOption;
    @Input() placeholder = '';

    @Output() optionSelected = new EventEmitter<FilterNgbDropdownOption>();
    @Output() clearCurrentFilter = new EventEmitter<boolean>();

    filterForm = this.formBuilder.nonNullable.group({
        search: [''],
    });
    subscriptions = new Subscription();
    filteredOptions: FilterNgbDropdownOption[] = [];
    selectedOption: FilterNgbDropdownOption = { value: '', label: '' };
    showPlaceholder = true;

    constructor(private readonly formBuilder: FormBuilder, private translateService: TranslateService) {}

    ngOnInit(): void {
        this.filteredOptions = this.options;

        this.subscriptions.add(
            this.filterForm.get('search')?.valueChanges.subscribe((value) => {
                if (value && value.length) {
                    this.filterOptions(value);
                } else {
                    this.filteredOptions = this.options;
                }
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        this.filteredOptions = this.options;

        if (
            changes['initialValue'] &&
            this.initialValue &&
            this.isFilterNgbDropdownOptionArray(this.options) &&
            this.options.find((vl: FilterNgbDropdownOption) => vl.value === this.initialValue?.value)
        ) {
            this.selectedOption = this.initialValue;
            this.filterForm.patchValue({ search: this.translateService.instant(this.initialValue.label.toString()) });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onClick(event: any) {
        if (event) {
            this.showPlaceholder = false;
        }
    }

    onBlur() {
        setTimeout(() => {
            if (!this.selectedOption || (this.selectedOption.value === '' && this.selectedOption.label === '')) {
                this.showPlaceholder = true;
            }
        }, 100);
    }

    isFilterNgbDropdownOption(value: FilterNgbDropdownOption | string): value is FilterNgbDropdownOption {
        return typeof value === 'object' && 'value' in value && 'label' in value;
    }

    isFilterNgbDropdownOptionArray(value: any): value is FilterNgbDropdownOption[] {
        return Array.isArray(value) && value.every((item: any) => this.isFilterNgbDropdownOption(item));
    }

    openDropdown() {
        if (this.dropdown && !this.dropdown.isOpen()) {
            this.dropdown.open();
        }
    }

    onDropdownChange(selectedOption: FilterNgbDropdownOption, event: any) {
        event.preventDefault();
        this.showPlaceholder = false;
        this.selectedOption = selectedOption;
        this.optionSelected.emit(this.selectedOption);
        this.filterForm.get('search')?.setValue('');
    }

    identifyOptionByValue(index: number, option: FilterNgbDropdownOption) {
        return option.value;
    }

    filterOptions(value: string) {
        if (this.isFilterNgbDropdownOptionArray(this.options)) {
            this.filteredOptions = this.options.filter((option) => {
                return this.translateService.instant(option.label).toLowerCase().includes(value.toLowerCase());
            });
        }
    }

    emitClearCurrentFilterEvent() {
        this.selectedOption = { value: '', label: '' };
        this.clearCurrentFilter.emit(true);
    }
}
