<div class="d-flex align-items-center">
    <div class="filter-content" *ngIf="currentFilter">
        <div class="d-flex flex-row">
            <span *ngIf="currentFilter.category?.value" class="selected-option">
                <img *ngIf="currentFilter.category.srcLeft" [src]="currentFilter.category.srcLeft" width="20px"
                    height="20px" class="me-1 rounded-circle" />
                {{currentFilter.category.label | translate}}
            </span>
            <span *ngIf="currentFilter.operation?.value" class="selected-option">
                <img *ngIf="currentFilter.operation.srcLeft" [src]="currentFilter.operation.srcLeft" width="20px"
                    height="20px" class="me-1 rounded-circle" />
                {{currentFilter.operation.label}}
            </span>
        </div>
    </div>

    <ng-container *ngIf="currentStep !== 'VALUE' && (currentDropdownValues$ | async) as currentDropdownValues">
        <ado-core-filter-dropdown-field [options]="currentDropdownValues" #dropdownFilter
            [placeholder]="placeholderName" (optionSelected)="onOptionSelected($event)"
            (clearCurrentFilter)="clearCurrentFilter($event)">
        </ado-core-filter-dropdown-field>
    </ng-container>

    <ng-container
        *ngIf="currentStep === 'VALUE' && fieldType === 'select' && (currentDropdownValues$ | async) as currentDropdownValues">
        <ado-core-filter-dropdown-field [options]="currentDropdownValues" adoCoreFilterDropdownFocus #dropdownValue
            [componentRef]="dropdownValue" [placeholder]="placeholderName" (optionSelected)="onOptionSelected($event)"
            (clearCurrentFilter)="clearCurrentFilter($event)" (stopEditing)="clearCurrentFilter(true)">
        </ado-core-filter-dropdown-field>
    </ng-container>

    <ng-container *ngIf="currentStep === 'VALUE' && fieldType === 'date'">
        <ado-core-filter-datepicker-field adoCoreFilterDatepickerFocus #datepickerValue
            [componentRefDate]="datepickerValue" [placeholder]="placeholderName" (dateSelected)="onDateSelected($event)"
            (clearCurrentFilter)="clearCurrentFilter($event)" (stopEditingDate)="clearCurrentFilter(true)">
        </ado-core-filter-datepicker-field>
    </ng-container>

    <ng-container *ngIf="currentStep === 'VALUE' && fieldType === 'text'">
        <ado-core-filter-text-field #textFilter (valueChanged)="onTextFieldEdit($event)"
            (clearFilter)="clearCurrentFilter($event)">
        </ado-core-filter-text-field>
    </ng-container>
</div>