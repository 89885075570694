import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingScope, LoginService } from '@axon/data-access-lib';
import { Observable, map, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import {
    LeaveScheduleGetResponse,
    LeaveSchedulesGetResponse,
    LeaveSchedulesInitializeResponse,
    LeaveSchedulesModifyRequest,
    LeaveSchedulesModifyResponse,
} from '../models';
import { LeaveScheduleHistoryItem } from '../models/leave-schedule-history-item.model';

@Injectable({ providedIn: 'root' })
export class LeaveSchedulesService {
    private leaveScheduleCache = new Map<string, LeaveScheduleGetResponse>();

    constructor(private http: HttpClient, private loginService: LoginService) {
        this.loginService.userLoggedDetails.subscribe((user) => {
            if (!user) {
                this.leaveScheduleCache.clear();
            }
        });
    }

    @LoadingScope()
    isLeaveSchedulesInitialized(year: number): Observable<boolean> {
        let yearParam = new HttpParams();
        yearParam = yearParam.append('year', year);

        return this.http
            .get<{ initializationStatus: boolean }>('api/leave-schedules/initialized', {
                params: yearParam,
            })
            .pipe(map((response) => response.initializationStatus));
    }

    @LoadingScope()
    initializeLeaveScheduleForAllEmployees(year: number): Observable<LeaveSchedulesInitializeResponse[]> {
        return this.http
            .post<{ items: LeaveSchedulesInitializeResponse[] }>(`api/leave-schedules/initialize`, {
                year: year,
            })
            .pipe(
                map((response) => {
                    return response.items;
                })
            );
    }

    @LoadingScope()
    initializeLeaveScheduleForSingleEmployee(employeeId: string, daysOffLimit: number, year: number): Observable<void> {
        return this.http.post<void>(`api/leave-schedules`, {
            employeeId: employeeId,
            daysOffLimit: daysOffLimit,
            year: year,
        });
    }

    @LoadingScope()
    getLeaveSchedulesByYear(year: number): Observable<LeaveSchedulesGetResponse[]> {
        let yearParam = new HttpParams();
        yearParam = yearParam.append('year', year);

        return this.http
            .get<{ items: LeaveSchedulesGetResponse[] }>(`api/leave-schedules`, {
                params: yearParam,
            })
            .pipe(
                map((response) => {
                    return response.items;
                })
            );
    }

    @LoadingScope()
    getLeaveScheduleForSingleEmployeeByYear(employeeId: string, year: number): Observable<LeaveScheduleGetResponse> {
        let params = new HttpParams();

        params = params.append('employeeId', employeeId);
        params = params.append('year', year);

        return this.http.get<LeaveScheduleGetResponse>(`api/leave-schedule`, { params });
    }

    @LoadingScope()
    modificationDaysOff(leaveSchedulesModifyRequest: LeaveSchedulesModifyRequest): Observable<LeaveSchedulesModifyResponse[]> {
        return this.http
            .post<{ items: LeaveSchedulesModifyResponse[] }>(`api/leave-schedules/days-off-limit`, leaveSchedulesModifyRequest)
            .pipe(
                map((response) => {
                    return response.items;
                })
            );
    }

    @LoadingScope()
    getLeaveScheduleForLoggedUser(year = new Date().getFullYear(), forceReload = false): Observable<LeaveScheduleGetResponse> {
        return this.loginService.userLoggedDetails.pipe(
            take(1),
            switchMap((user) => {
                const cacheKey = `${user.employeeId}-${year}`;
                const cachedData = this.leaveScheduleCache.get(cacheKey);

                if (!cachedData || forceReload) {
                    let params = new HttpParams();
                    params = params.append('employeeId', user.employeeId);
                    params = params.append('year', year.toString());

                    return this.http
                        .get<LeaveScheduleGetResponse>(`/api/leave-schedule`, { params })
                        .pipe(tap((data) => this.leaveScheduleCache.set(cacheKey, data)));
                }
                return of(cachedData);
            })
        );
    }

    @LoadingScope()
    getLeaveScheduleHistory(leaveScheduleId: string): Observable<LeaveScheduleHistoryItem[]> {
        return this.http.get<LeaveScheduleHistoryItem[]>(`api/leave-schedules/${leaveScheduleId}/history`);
    }
}
