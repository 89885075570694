import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@axon-dot/fe/shared/environments';
import { LoadingScope } from '@axon/data-access-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestsByTeamIdAndMonthAndYear } from '../models/requests-by-team-and-month.model';

@Injectable({providedIn: 'root'})
export class HolidayService {
    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private env: Environment
    ) {}

    @LoadingScope()
    public getHolidays(
        teamId?: number,
        month?: number,
        year?: number
    ): Observable<RequestsByTeamIdAndMonthAndYear[]> {
        let params = new HttpParams();

        if (teamId && teamId !== -1) {
            params = params.append('teamId', teamId);
        }
        if (month) {
            params = params.append('month', month);
        }
        if (year) {
            params = params.append('year', year);
        }

        return this.http
            .get<{ items: RequestsByTeamIdAndMonthAndYear[] }>(
                `/api/leave-days`,
                { params }
            )
            .pipe(
                map(responseData => {
                    if(!responseData.items){
                        return [];
                    }
                    return responseData.items.map((item, index) => {
                        return {
                            id: index,
                            ...item,
                        };
                    });
                })
            );
    }
}
