import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MODALS_COMPONENTS } from './components';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [...MODALS_COMPONENTS],
    imports: [CommonModule, TranslateModule],
    exports: [...MODALS_COMPONENTS],
})
export class ModalsModule {}
