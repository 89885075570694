<div class="stepper">
    <ng-container *ngFor="let step of steps; let i = index">
        <div
            class="step"
            [ngClass]="{
                active: i === currentStep,
                completed: step.status !== 'PENDING'
            }"
            (click)="goToStep(i)"
        >
            <div class="step-circle" *ngIf="step.status !== 'COMPLETED' && step.status !== 'PENDING'">
                {{ i + 1 }}
            </div>
            <i
                class="step-circle fa-solid fa-check"
                *ngIf="step.status === 'COMPLETED'"
            ></i>
            <i
                class="step-circle fa-solid fa-lock"
                *ngIf="step.status === 'PENDING'"
            ></i>
            <div class="fw-bold text-center">
                {{ step.title | translate }}
            </div>
        </div>

        <div
            class="step-line"
            *ngIf="i < steps.length - 1"
            [class.completed]="step.status === 'COMPLETED'"
        ></div>
    </ng-container>
</div>
