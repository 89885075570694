import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {tap} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ProfilePictureService {

    private profilePictures$ = new BehaviorSubject<Map<string, Blob | null>>(new Map<string, Blob | null>());

    constructor(private http: HttpClient) {}

    uploadProfilePicture(employeeId: string, file: Blob, filename: string): Observable<unknown> {
        const formData: FormData = new FormData();

        if (file.type === 'image/jpeg') {
            formData.append('profileImage', file, `${filename}.jpeg`);
        } else {
            if (file.type === 'image/png') {
                formData.append('profileImage', file, `${filename}.png`);
            }
        }

        return this.http.post(`api/employees/${employeeId}/profile-picture`, formData)
            .pipe(tap(() => this.updateCachedProfilePicture(employeeId, file)));
    }

    getProfilePicture(employeeId: string): Observable<Blob> {
        const profilePicture = this.profilePictures$.value.get(employeeId);
        if (profilePicture) {
            return of(profilePicture);
        } else {
            return this.fetchProfilePicture(employeeId);
        }
    }

    private fetchProfilePicture(employeeId: string): Observable<Blob> {
        return this.http.get(`api/employees/${employeeId}/profile-picture`, { responseType: 'blob' })
        .pipe(
            tap((blob: Blob) => {
                this.updateCachedProfilePicture(employeeId, blob);
            })
        );
    }

    private updateCachedProfilePicture(employeeId: string, picture: Blob) {
        const profilePicture = new Map<string, Blob | null>(this.profilePictures$.getValue());
        profilePicture.set(employeeId, picture);
        this.profilePictures$.next(profilePicture);
    }

}
