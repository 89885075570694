import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, map } from 'rxjs';
import { MiscService, OptionSelect, OptionType } from '@axon/data-access-lib';

export const userRolesResolver: ResolveFn<OptionSelect[]> = (): Observable<OptionSelect[]> => {
    const miscService = inject(MiscService);

    return miscService.getOptionsSelect(OptionType.USER_ROLES).pipe(
        map<string[], OptionSelect[]>(userRoles => {
            return userRoles.map(role => {
                return {
                    value: role,
                    label: `lovs.roles.${role}`,
                };
            });
        })
    );
};
