
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, filter, map, of, switchMap, take, tap } from 'rxjs';

import { LoginService, UserDetails } from '@axon/data-access-lib';
import { LegalDayOff, LegalDaysOffService } from '@axon-dot-fe/core';
import { inject } from '@angular/core';

export const freeDaysResolver: ResolveFn<LegalDayOff[] | null> = (route: ActivatedRouteSnapshot): Observable<LegalDayOff[] | null> => {
    /**
     * The previous year for which legal days off were fetched.
     */
    let previousYear = 0;
    /**
     * Cached legal days off array to avoid unnecessary API calls.
     */
    let legalDaysOff: LegalDayOff[] = [];

    const freeDayService = inject(LegalDaysOffService);
    const loginService = inject(LoginService);

    /**
     * Resolves the legal days off information for the specified year based on the user's calendar.
     * The method is responsible for fetching legal days off information for the specified
     * year and the user's calendar. It checks if the data for the same year is already available
     * in the cache. If so, it returns the cached data to avoid unnecessary API calls. If the data
     * is not in the cache or if the year has changed, it calls the `getLegalDaysOffByYearAndCalendar`
     * method from the `LegalDaysOffService` to fetch the legal days off data. The response is then
     * cached for subsequent requests. The observable completes after emitting one value.
     *
     * @param {ActivatedRouteSnapshot} route - The activated route snapshot.
     * @returns {Observable<LegalDayOff[] | null>} - An observable that emits the legal days off
     * data for the specified year or null in case of an error. The observable completes after emitting one value.
     */

        const currentYear = route.params.year;
        if (currentYear === previousYear) {
            return of(legalDaysOff);
        }

        previousYear = currentYear;

        return loginService.userLoggedDetails.pipe(
            filter((user) => !!user),
            take(1),
            switchMap((userDetails: UserDetails) => {
                return freeDayService.getLegalDaysOffAndWeekendsByYearAndCalendar(currentYear, userDetails.calendar).pipe(
                    filter((value) => !!value),
                    map((vl) => vl as LegalDayOff[]),
                    take(1),
                    tap((response) => (legalDaysOff = response))
                );
            })
        );
    
}