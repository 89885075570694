import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { LoginService } from '@axon/data-access-lib';
import { Observable, take, map } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ChangeGeneratedPasswordGuard {
    constructor(private loginService: LoginService, private router: Router) {}

    canActivate():
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.loginService.userLoggedDetails.pipe(
            take(1),
            map(user => {
                if (!user) {
                    this.router.navigate(['/login']);
                    return false;
                }
                if (user.isAutogeneratedPassword === true) {
                    return true;
                } else {
                    this.router.navigate(['/login']);
                    return false;
                }
            })
        );
    }
}
