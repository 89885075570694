import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericModalDetails } from '../../models';
import { DarkMode } from '../../../../models';
import { DarkModeService } from '@axon/axon-lib';

@Component({
    selector: 'ado-core-generic-modal',
    templateUrl: './generic-modal.component.html',
    styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent implements OnInit, AfterViewInit {
    @ViewChild('confirmButton') confirmButton!: ElementRef;

    @Input() public details: GenericModalDetails = {
        header: 'modal.header',
        body: 'modal.body',
        cancelButton: 'entity.cancel',
        confirmButton: 'entity.yes',
        cancelButtonClass: 'btn-secondary',
        confirmButtonClass: 'btn-primary',
        params: null,
    };
    darkMode?: DarkMode;

    constructor(private activeModal: NgbActiveModal, private darkModeService: DarkModeService) {}

    ngOnInit(): void {
        this.darkMode = this.darkModeService.getDarkModeSubjectValue() ? DarkMode.DARK : DarkMode.LIGHT;
        this.details.confirmButtonClass = this.details.confirmButtonClass || 'btn-primary';
        this.details.cancelButtonClass = this.details.cancelButtonClass || 'btn-secondary';
        this.details.params = this.details.params || null;
    }

    ngAfterViewInit(): void {
        this.confirmButton.nativeElement.focus();
    }

    onCancel() {
        this.activeModal.close(false);
    }

    onConfirm() {
        this.activeModal.close(true);
    }
}
