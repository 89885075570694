import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { MiscService, OptionSelect, OptionType } from '@axon/data-access-lib';
import { Observable, map } from 'rxjs';

export const leaveRequestStatusResolver: ResolveFn<OptionSelect[]> = (): Observable<
    OptionSelect[]
> => {
    const miscService = inject(MiscService);

    return miscService.getOptionsSelect(OptionType.LEAVE_REQUEST_STATUS).pipe(
        map<string[], OptionSelect[]>(leaveRequestStatus => {
            return leaveRequestStatus.map(status => {
                return {
                    value: status,
                    label: `lovs.leave-request-status.${status}`,
                };
            });
        })
    );
};
