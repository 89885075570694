<nav class="navbar navbar-dark bg-dark-subtle d-md-none">
    <div class="container-fluid d-flex align-items-center px-3">
        <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggleExternalContent"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <a class="navbar-brand mx-auto" routerLinkActive="active" routerLink="/">
            <ng-container [ngSwitch]="appMode">
                <img
                    *ngSwitchCase="'LOCAL'"
                    class="img-fluid"
                    src="assets/images/logo-local.png"
                    class="d-inline-block align-items-end img-logo"
                    alt="Axon Soft Logo - Local"
                    loading="lazy" />
                <img
                    *ngSwitchCase="'PUBLIC'"
                    class="img-fluid"
                    src="assets/images/logo-public.png"
                    class="d-inline-block align-items-end img-logo"
                    alt="Axon Soft Logo - Public"
                    loading="lazy" />
            </ng-container>
        </a>

        <ng-container *ngTemplateOutlet="detailsMenu"></ng-container>
    </div>
</nav>

<div class="collapse navbar-collapse d-md-none" id="navbarToggleExternalContent">
    <div class="bg-dark-subtle py-2 px-3 nav-pills nav-item">
        <ng-container *ngTemplateOutlet="links"></ng-container>
        <ng-container *ngTemplateOutlet="dropdownCv"></ng-container>
    </div>
</div>

<nav class="navbar navbar-expand-sm navbar-dark bg-dark-subtle d-none d-md-block">
    <div class="container">
        <div class="navbar-nav d-flex flex-grow-1 justify-content-between align-items-center">
            <!-- This main navigation links are defined here -->
            <a class="navbar-brand" routerLinkActive="active" routerLink="/">
                <ng-container [ngSwitch]="appMode">
                    <img
                        *ngSwitchCase="'LOCAL'"
                        class="img-fluid"
                        src="assets/images/logo-local.png"
                        class="d-inline-block align-items-end img-logo"
                        alt="Axon Soft Logo - Local"
                        loading="lazy" />
                    <img
                        *ngSwitchCase="'PUBLIC'"
                        class="img-fluid"
                        src="assets/images/logo.png"
                        class="d-inline-block align-items-end img-logo"
                        alt="Axon Soft Logo - Public"
                        loading="lazy" />
                </ng-container>
            </a>

            <!-- Each link has the routerLink property set to a different route -->
            <div
                class="d-flex bg-gray bg-opacity-25 rounded-pill py-2 px-3 nav-pills nav-item nav-header text-center">
                <ng-container *ngTemplateOutlet="links"></ng-container>
                <ng-container *ngTemplateOutlet="dropdownCv" ></ng-container>
            </div>

            <ng-container *ngTemplateOutlet="detailsMenu"></ng-container>
        </div>
    </div>
</nav>

<ng-template #links>
    <div
        *ngIf="authorizationRole === 'HR' && !isPublicSecured"
        class="nav-item dropdown d-flex align-items-center">
        <a
            class="nav-link w-100 d-flex flex-row align-items-center"
            href="#"
            id="navbarHrDropdown"
            role="button"
            data-bs-toggle="dropdown"
            [attr.aria-expanded]="isHrDropdownOpen"
            (click)="toggleHrDropdown()">
            {{ 'header.hr-tools' | translate }}

            <div
                class="trigger-icon"
                [ngClass]="isHrDropdownOpen ? 'rotate-180' : ''">
                <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 9L12.0368 15.9632L5.07366 9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
        </a>
        <ul
            class="dropdown-menu dropdown-menu-end dropdown-menu-md-start w-100 dropdown-menu-width px-2"
            aria-labelledby="navbarHrDropdown">
            <li>
                <ng-container *adoCoreFeatureFlag="'employees'">
                    <a
                        class="nav-link"
                        routerLinkActive="active"
                        routerLink="/employees"
                        data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                        >
                        {{ 'header.employees' | translate }}
                    </a>
                </ng-container>
            </li>
            <li>
                <ng-container *adoCoreFeatureFlag="'assign-holidays'">
                    <a
                        class="nav-link"
                        routerLinkActive="active"
                        routerLink="/assign-holidays"
                        data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                        {{ 'header.free-days' | translate }}
                    </a>
                </ng-container>
            </li>
            <li>
                <ng-container *adoCoreFeatureFlag="'teams'">
                    <a
                        class="nav-link"
                        routerLinkActive="active"
                        routerLink="/teams"
                        data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                        {{ 'header.teams' | translate }}
                    </a>
                </ng-container>
            </li>
        </ul>
    </div>

    <div
        *ngIf="('ADMIN' | hasRole | async) && !isPublicSecured"
        class="nav-item dropdown d-flex align-items-center">
        <a
            class="nav-link w-100 d-flex flex-row align-items-center"
            href="#"
            id="navbarAdminDropdown"
            role="button"
            data-bs-toggle="dropdown"
            [attr.aria-expanded]="isAdminDropdownOpen"
            (click)="toggleAdminDropdown()"
            *adoCoreFeatureFlag="'roles-mapping'"
            >
            {{ 'header.admin-tools' | translate }}

            <div
                class="trigger-icon"
                [ngClass]="isAdminDropdownOpen ? 'rotate-180' : ''">
                <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 9L12.0368 15.9632L5.07366 9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
        </a>
        <ul
            class="dropdown-menu dropdown-menu-end dropdown-menu-md-start w-100 dropdown-menu-width bg-dark-subtle border-dark-subtle"
            aria-labelledby="navbarAdminDropdown">
            <li>
                <ng-container *adoCoreFeatureFlag="'crud-roles'">
                    <a
                        class="nav-link"
                        routerLinkActive="active"
                        routerLink="/admin/roles"
                        data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                        {{ 'header.roles' | translate }}
                    </a>
                </ng-container>
            </li>
            <li>
                <ng-container *adoCoreFeatureFlag="'roles-mapping'">
                    <a
                        class="nav-link"
                        routerLinkActive="active"
                        routerLink="/admin/employee"
                        data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                        {{ 'header.roles-mapping' | translate }}
                    </a>
                </ng-container>
            </li>
           
        </ul>
    </div>

    <ng-container *adoCoreFeatureFlag="'leave-requests'">
        <a
            class="nav-link"
            routerLinkActive="active"
            routerLink="/requests"
            data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
            *ngIf="
                (authorizationRole === 'HR' || authorizationRole === 'TEAM_LEADER') && !isPublicSecured
            ">
            {{ 'header.leave-requests' | translate }}
        </a>
    </ng-container>

    <ng-container *adoCoreFeatureFlag="'holidays'">
        <a class="nav-link" routerLinkActive="active" routerLink="/holidays"
        data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
            {{ 'header.individual-holidays' | translate }}
        </a>
    </ng-container>

    <ng-container *adoCoreFeatureFlag="'planned-holidays'">
        <a class="nav-link" routerLinkActive="active" routerLink="/planned-holidays" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
            {{ 'header.planned-holidays' | translate }}
        </a>
    </ng-container>
</ng-template>

<ng-template #detailsMenu>
    <div class="nav-item dropdown">
        <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false">
            {{ username }}
        </a>
        <ul id="userDropdownMenuList" class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
            <li>
                <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#userDropdownMenuList" routerLinkActive="active" routerLink="/profile/details">
                    <i class="fa-solid fa-user me-2"></i>{{ 'header.my-profile' | translate }}
                </a>
            </li>
            <li *ngIf="!hideDarkMode">
                <a class="dropdown-item switch-dark-mode">
                    <div class="d-flex flex-row d-flex justify-content-between">
                        <a>
                            <i class="fa-solid me-2" [ngClass]="darkMode.icon"></i> {{ darkMode.text | translate }}
                        </a>
                        <div class="d-flex flex-row align-items-center">
                            <div class="form-check form-switch">
                                <input
                                    [(ngModel)]="darkMode.isDarkMode"
                                    (change)="onCheckboxDarkMode()"
                                    class="form-check-input ms-auto"
                                    type="checkbox"
                                    role="switch" />
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a class="dropdown-item">
                    <div class="dropstart">
                        <div
                            class="dropdown-toggle d-flex align-items-center cursor-pointer dropdown-lang"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <img
                                [src]="
                                    'assets/images/languages/' + selectedLanguage.translate + '.png'
                                "
                                width="16px"
                                height="16px"
                                class="me-2 rounded-circle" />
                            {{ selectedLanguage.text | translate }}
                            <i class="fa-regular fa-ellipsis-vertical ms-auto pe-2"></i>
                        </div>
                        <ul class="dropdown-menu">
                            <li
                                *ngFor="
                                    let lang of languages;
                                    trackBy: identifyLanguageByTranslate
                                ">
                                <a
                                    class="dropdown-item d-flex align-items-center cursor-pointer"
                                    data-bs-toggle="collapse" data-bs-target="#userDropdownMenuList"
                                    (click)="onChangeLanguage(lang)"
                                    ><img
                                        [src]="'assets/images/languages/' + lang.translate + '.png'"
                                        width="20px"
                                        height="20px"
                                        class="me-2 rounded-circle" />
                                    {{ lang.text | translate }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </a>
            </li>
            <li>
                <a
                    class="dropdown-item dropdown-item-disconnect cursor-pointer"
                    (click)="onLogout()">
                    <i class="fa-solid fa-right-from-bracket me-2"></i
                    >{{ 'header.disconnect' | translate }}
                </a>
            </li>
        </ul>
    </div>
</ng-template>

<ng-template #dropdownCv>
    <ng-container *adoCoreFeatureFlag="'cv'">
        <div
            *ngIf="!isPublicSecured"
            class="nav-item dropdown d-flex align-items-center">
            <a
                class="nav-link w-100"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                CV
            </a>
            <ul
                class="dropdown-menu dropdown-menu-end dropdown-menu-md-start w-100 dropdown-menu-width bg-dark-subtle border-dark-subtle"
                aria-labelledby="navbarDropdown">
                <li>
                    <a
                        class="nav-link"
                        routerLinkActive="active"
                        routerLink="/cv/details"
                        *ngIf="!isPublicSecured">
                        {{ 'header.cv.my-cv' | translate }}
                    </a>
                </li>
                <li>
                    <a
                        class="nav-link"
                        routerLinkActive="active"
                        routerLink="/cv/employees"
                        *ngIf="authorizationRole === 'HR' && !isPublicSecured">
                        {{ 'header.cv.employees' | translate }}
                    </a>
                </li>
                <li>
                    <a
                        class="nav-link"
                        routerLinkActive="active"
                        routerLink="/cv/assigned"
                        *ngIf="authorizationRole === 'TEAM_LEADER' && !isPublicSecured">
                        {{ 'header.cv.reviews' | translate }}
                    </a>
                </li>
            </ul>
        </div>
    </ng-container>
</ng-template>
