import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[adoCoreFocusDatepickerClass]'
})
export class FocusDatepickerDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('focus', ['$event.target'])
  onFocus(target: HTMLInputElement) {
    this.renderer.addClass(this.el.nativeElement.parentNode, 'focused-group');
  }

  @HostListener('blur', ['$event.target'])
  onBlur(target: HTMLInputElement) {
    this.renderer.removeClass(this.el.nativeElement.parentNode, 'focused-group');
  }
}
