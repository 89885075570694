import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingScope } from '../decorators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TeamDto } from '../models';

@Injectable({providedIn: 'root'})
export class TeamsService {
    constructor(private http: HttpClient) {}

    @LoadingScope()
    getTeams(): Observable<TeamDto[]> {
        return this.http.get<{ items: TeamDto[] }>(`/api/teams`).pipe(
            map(responseData => {
                return responseData.items;
            })
        );
    }
}
