export enum ItemStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
}

export interface ItemStepper {
  route: string;
  title: string;
  status?: ItemStatus;
}
