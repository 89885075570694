import { CdkDrag, CdkDragDrop, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { DragAndDropModel } from '../../models';

@Component({
    standalone: true,
    selector: 'ado-core-drag-and-drop',
    templateUrl: './drag-and-drop.component.html',
    styleUrls: ['./drag-and-drop.component.scss'],
    imports: [CdkDropListGroup, CdkDropList, CdkDrag, CommonModule, TranslateModule, MatTooltipModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DragAndDropComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragAndDropComponent implements ControlValueAccessor, OnChanges {
    @Input() sourceTitle = 'entity.available-roles';
    @Input() destinationTitle = 'entity.assigned-roles';
    @Input() availableOptions: DragAndDropModel[] = [];

    selectedOptions: DragAndDropModel[] = [];
    remainingOptions: DragAndDropModel[] = [];
    disabled = false;

    private _onChange?: (value: DragAndDropModel[] | null) => void;
    private _onValidationChange?: () => void;
    private _onTouched?: () => void;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['availableOptions'] && changes['availableOptions'].currentValue !== changes['availableOptions'].previousValue) {
            this.setRemainingOptions();
        }
    }

    writeValue(selectedOptions: DragAndDropModel[]): void {
        this.selectedOptions = [...selectedOptions];
        this.setRemainingOptions();
        this.cdr.markForCheck();
    }

    private setRemainingOptions() {
        const idsInList2 = new Map();
        this.selectedOptions.forEach((opt) => {
            idsInList2.set(opt.name, opt);
        });

        this.remainingOptions = this.availableOptions.filter((o1) => !idsInList2.has(o1.name));
    }

    registerOnChange(fn: () => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cdr.markForCheck();
    }

    validate(): ValidationErrors | null {
        return null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidationChange = fn;
    }

    drop(event: CdkDragDrop<DragAndDropModel[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }

        if (this._onChange) {
            this._onChange(this.selectedOptions);
        }
    }

    identifyOption(index: number, option: DragAndDropModel) {
        return option.name;
    }

    moveAllToDestination(): void {
        this.selectedOptions.push(...this.remainingOptions);
        this.remainingOptions = [];
        if (this._onChange) {
            this._onChange(this.selectedOptions);
        }
    }

    moveAllToSource(): void {
        this.remainingOptions.push(...this.selectedOptions);
        this.selectedOptions = [];
        if (this._onChange) {
            this._onChange(this.selectedOptions);
        }
    }
}
