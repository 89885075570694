<div
    class="initials-image text-white"
    [ngStyle]="{ 'background-color': backgroundColor }"
>
    <ng-container *ngIf="!initials">
        <i class="fa-regular fa-user placeholder-icon"></i>
    </ng-container>
    <ng-container *ngIf="initials">
        <span>{{ initials }}</span>
    </ng-container>
</div>
