import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'ado-core-filter-edit-item-label-datepicker',
    templateUrl: './filter-edit-item-label-datepicker.component.html',
    styleUrls: ['./filter-edit-item-label-datepicker.component.scss'],
})
export class FilterEditItemLabelDatepickerComponent implements OnChanges {
    @Input() filterField?: string;
    @Input() placeholder?: string;
    @Input() deletable = false;
    @Input() open = false;
    @Output() selectChange = new EventEmitter<string>();
    @Output() filterDeleted = new EventEmitter<boolean>();
    isOpen = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes['open'] && changes['open'].previousValue !== changes['open'].currentValue) {
            this.isOpen = this.open;
        } else {
            this.isOpen = false;
        }
    }

    startEditing(value: string): void {
        this.isOpen = true;
    }

    stopEditingDate(value: string) {
        if (!value) {
            this.onDeleteFilter();
        }
        this.isOpen = false;
    }

    onSelectChange(option: string) {
        this.isOpen = false;
        this.selectChange.emit(option);
    }

    onDeleteFilter() {
        if (this.deletable) {
            this.filterDeleted.emit(true);
            this.isOpen = false;
        }
    }
}
