import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbDate, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FormatDate } from '@axon/axon-lib';

@Component({
    selector: 'ado-core-filter-datepicker-field',
    templateUrl: './filter-datepicker-field.component.html',
    styleUrls: ['./filter-datepicker-field.component.scss'],
})
export class FilterDatepickerFieldComponent implements OnDestroy {
    @ViewChild('myDatepicker', { static: false, read: NgbDropdown }) datepicker?: NgbDropdown;
    @ViewChild('input') input?: ElementRef;
    @Input() initialValue?: string;
    @Input() placeholder = '';
    @Output() dateSelected = new EventEmitter<string>();
    @Output() clearCurrentFilter = new EventEmitter<boolean>();
    date?: { year: number; month: number };
    filterForm = this.formBuilder.nonNullable.group({
        date: [''],
    });
    subscriptions = new Subscription();
    selectedDate = '';
    showPlaceholder = true;

    constructor(private readonly formBuilder: FormBuilder) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onDateSelect(event: NgbDate) {
        if (event) {
            this.selectedDate = FormatDate.convertNgbDateToStringDate(event);
            this.showPlaceholder = false;
            this.dateSelected.emit(this.selectedDate);
            this.filterForm.get('date')?.setValue('');
        }
    }

    onClick(event: any) {
        if (event) {
            this.showPlaceholder = false;
        }
    }

    onBlur() {
        setTimeout(() => {
            if (!this.selectedDate || this.selectedDate === '') {
                this.showPlaceholder = true;
            }
        }, 100);
    }

    openDropdown() {
        if (this.datepicker && !this.datepicker.isOpen()) {
            this.datepicker.open();
        }
    }

    emitClearCurrentFilterEvent() {
        this.selectedDate = '';
        this.clearCurrentFilter.emit(true);
    }
}
