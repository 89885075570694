<div class="user-profile">
    <div *ngIf="profilePicture">
        <img [src]="profilePicture"
             alt="">
    </div>
    <ado-core-initials-image [style.--initials-image-size]="'2em'"
                             [style.--initials-font-size]="'1em'"
                             [firstName]="name"
                             [lastName]="surname"
                             *ngIf="!profilePicture"></ado-core-initials-image>
    <span class="name">{{ name + ' ' + surname}}</span>
</div>