import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from '../../utils';

@Component({
    selector: 'ado-core-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent implements ControlValueAccessor, Validator {
    @Input() title = 'entity.date';
    @Input() minDate!: NgbDate;
    @Input() maxDate!: NgbDate;

    public readonly dateControl = new FormControl<NgbDateStruct | null>(null, [CustomValidators.specialCharactersValidator()]);

    private _onChange?: (value: NgbDateStruct | null) => void;
    private onValidationChange?: () => void;
    private _onTouched?: () => void;

    constructor(private cdr: ChangeDetectorRef) { }

    writeValue(obj: NgbDate): void {
        this.dateControl.patchValue(obj, { emitEvent: false });
    }

    registerOnChange(fn: () => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
        this.dateControl.valueChanges.subscribe((value: NgbDateStruct | null) => {
            if (this._onChange && this._onTouched && this.onValidationChange) {
                this._onChange(value);
                this._onTouched();
                // TODO: Check if this is needed and how we should use the Validator interface
                // this.onValidationChange();
            }
        });
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.dateControl.disable();
        } else {
            this.dateControl.enable();
        }
        
        this.cdr.markForCheck();
    }

    validate(): ValidationErrors | null {
        return this.dateControl.errors;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onValidationChange = fn;
    }
}
