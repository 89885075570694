import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { DrawerBodyDirective } from '../../directives';
import { enterLeaveRightLeft } from '../../animations';

@Component({
  selector: 'ado-core-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  animations: enterLeaveRightLeft,
  exportAs: "drawer",
})
export class DrawerComponent {

  @Input() isOpen = false;
  @Input() position: 'right' | 'left' = 'right';
  @Input() mode: 'side' | 'over' = 'over';
  @Input() hasBackdrop = false;
  @ContentChild(DrawerBodyDirective, { read: TemplateRef }) drawerBodyRef?: TemplateRef<unknown>;

  @Output() drawerClosed = new EventEmitter();

  close() {
    this.drawerClosed.emit();
  }
}
