import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@axon-dot-fe/environments';
import { ENVIRONMENT } from '@axon-dot/fe/shared/environments';
import { AxonCoreLibModule, LoaderModule, ToastNotificationsModule } from '@axon/axon-lib';
import { CustomRouterStateSerializer, FeatureFlagsService, RouterStoreModule } from '@axon/data-access-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor, LoginInterceptorService } from './core';
import { CoreModule } from './core/core.module';
import { UiModule } from './core/ui/ui.module';
import { LoginModule } from './features/login';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const initializeFeatureFlags = (featureFlagsService: FeatureFlagsService) => () => featureFlagsService.loadConfig();

@NgModule({
    declarations: [AppComponent],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoginInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        { provide: ENVIRONMENT, useValue: environment },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeFeatureFlags,
            deps: [FeatureFlagsService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    imports: [
        CoreModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        LoaderModule,
        UiModule,
        AxonCoreLibModule,
        LoginModule,
        ToastNotificationsModule,
        BrowserModule,
        RouterStoreModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomRouterStateSerializer,
            navigationActionTiming: NavigationActionTiming.PostActivation,
        }),
        StoreModule.forRoot(),
        EffectsModule.forRoot({}),
        StoreDevtoolsModule.instrument({
            logOnly: environment.production,
            trace: !environment.production
        }),
    ],
})
export class AppModule {}
