import {
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from "@angular/core";
import { FeatureFlagsService } from "@axon/data-access-lib";
import { Subscription } from "rxjs";

@Directive({
    selector: "[adoCoreFeatureFlag]"
})
export class FeatureFlagDirective implements OnInit, OnDestroy {
    @Input('adoCoreFeatureFlag') featureFlag?: string;

    private featureFlagSubscription = new Subscription();

    constructor(
        private tpl: TemplateRef<any>,
        private vcr: ViewContainerRef,
        private featureFlagService: FeatureFlagsService
    ) {}

    ngOnInit() {
        if (this.featureFlag) {
            this.featureFlagSubscription = this.featureFlagService.isFeatureFlagEnabled$(this.featureFlag)
                .subscribe((isEnabled) => {
                    if (isEnabled) {
                        this.vcr.createEmbeddedView(this.tpl);
                    }
                });
        }
    }

    ngOnDestroy() {
        this.featureFlagSubscription.unsubscribe();
    }
}
