import { Pipe, PipeTransform } from "@angular/core";
import { Role } from "@axon/data-access-lib";

@Pipe({
    name: 'userHrOrTeamLeaderRole',
    pure: true
})
export class UserRolePipe implements PipeTransform {
  
    transform(value: Role | undefined): boolean {
      return value === Role.HR || value === Role.TEAMLEAD;
    }
}
