// onboarding.guard.ts
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LoginService } from '@axon/data-access-lib';

export function OnboardingGuard(): CanActivateFn {
    return () => {
        const loginService: LoginService = inject(LoginService);
        const router: Router = inject(Router);

        const tokenDetailsDecoded = loginService.tokenDetailsDecoded;

        if(!tokenDetailsDecoded){
            return false;
        }

        if (tokenDetailsDecoded.isAutogeneratedPassword) {
            router.navigate(['/onboarding/setPassword']);
            return false;
        }
        if (tokenDetailsDecoded.mandatory2FA && !tokenDetailsDecoded.is2FAEnabled) {
            router.navigate(['/onboarding/2fa']);
            return false;
        }
        return true;
    };
}
