import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FilterDropdownFieldComponent } from '../components';
import { FilterNgbDropdownOption } from '../models';

@Directive({
    selector: '[adoCoreFilterDropdownFocus]',
})
export class FilterEditItemDropdownFocusDirective implements AfterViewInit {
    @Input() componentRef?: FilterDropdownFieldComponent;
    @Output() stopEditing = new EventEmitter<FilterNgbDropdownOption>();

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        if (this.componentRef && this.componentRef.input) {
            this.componentRef.input.nativeElement.click();
            this.componentRef.input.nativeElement.focus();
            this.cdr.detectChanges();
        }
    }

    @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            if (this.componentRef?.initialValue) {
                this.stopEditing.emit(this.componentRef?.initialValue);
            }
        }
    }

    @HostListener('focusout', ['$event']) onBlur(event: FocusEvent) {
        setTimeout(() => {
            if (this.componentRef?.selectedOption.value) {
                this.stopEditing.emit(this.componentRef?.initialValue);
            } else {
                this.stopEditing.emit();
            }
        }, 200);
    }
}
