import { AbstractControl, FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormatDate } from './format-date';
import { Constants } from './constants';

export class CustomValidators {
    static reversedDatesValidator(formGroup: FormGroup): {
        [s: string]: boolean;
    } | null {
        const controls = formGroup.controls;
        if (controls) {
            const start = controls.startDate.value as NgbDateStruct;
            const end = controls.endDate.value as NgbDateStruct;

            if (start && end) {
                const startNgbDate = new NgbDate(start.year, start.month, start.day);
                const endNgbDate = new NgbDate(end.year, end.month, end.day);

                if (!startNgbDate.before(endNgbDate) && !startNgbDate.equals(endNgbDate)) {
                    return {
                        datesAreReversed: true,
                    };
                }
            }
        }
        return null;
    }

    static confirmPasswordValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const password = control.get('newPassword');
            const confirmPassword = control.get('newPasswordConfirmation');

            if (password?.value !== confirmPassword?.value) {
                return { passwordMismatch: true };
            }
            return null;
        };
    }

    static passwordValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const passwordRegex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*${Constants.SPECIAL_CHARACTERS.source})`);
        const valid = passwordRegex.test(control.value);
        if (!valid) {
            return { invalidPassword: true };
        }
        return null;
    }

    /**
     * Validator function that checks if the selected date is before a specified minimum date.
     * @param {string} minDate - The minimum allowed date in string format (YYYY-MM-DD).
     * @returns {(control: AbstractControl) => { [s: string]: boolean } | null} - Validator function.
     */
    static pastDatesValidator(minDate: string) {
        return (control: AbstractControl): { [s: string]: boolean } | null => {
            const ngbDate = control.value;
            if (ngbDate) {
                const date = FormatDate.convertNgbDateToStringDate(ngbDate);

                if (date < minDate) {
                    return { dateIsPassed: true };
                }
            }
            return null;
        };
    }

    static nextYearConstraintValidator(formGroup: AbstractControl): {
        [s: string]: boolean;
    } | null {
        const controls = (formGroup as FormGroup).getRawValue();

        if (controls) {
            const start = controls.dates?.startDate;
            const end = controls.dates?.endDate;

            if (start && end) {
                const startDate = FormatDate.convertNgbDateToDate(start);
                const endDate = FormatDate.convertNgbDateToDate(end);
                const currentYear = new Date().getFullYear();

                if (startDate.getFullYear() > currentYear || endDate.getFullYear() > currentYear) {
                    return { notNextYearConstraint: true };
                }
            }
        }

        return null;
    }

    /**
     *  The validator checks the value of the form control, expected to be a date, to determine if it falls on a Saturday or Sunday.
     *  @returns {ValidatorFn} A validator function that takes an `AbstractControl` instance as its argument.
     */
    static excludeWeekendValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }
            const selectedDate = FormatDate.convertNgbDateToDate(control.value);
            const dayOfWeek = selectedDate.getDay();

            /**
             * Check if it's Saturday(6) or Sunday(0)
             */
            if (dayOfWeek === 0 || dayOfWeek === 6) {
                return { weekendError: true };
            }

            return null;
        };
    }

    /**
     * Method used to verify if the selected operation is DELETE operation and determine the min days off for selected employees for checking if the
     * number of days wanted to remove is greater than number of days off an employee has
     * @param {any[]} selectedEmployees - The list of employees.
     * @returns {ValidatorFn} - Validator function.
     */
    static validateNumberOfDaysOff(selectedEmployees: any[]): ValidatorFn {
        let minDaysOff = 0;

        return (formGroup: AbstractControl): ValidationErrors | null => {
            if (formGroup.get('type')?.value === 'DELETE' && selectedEmployees.length > 0) {
                minDaysOff = Number(selectedEmployees[0].remainingDaysOff);
                selectedEmployees.forEach((emp) => {
                    if (Number(emp.remainingDaysOff) < Number(minDaysOff)) {
                        minDaysOff = Number(emp.remainingDaysOff);
                    }
                });
                if (Number(formGroup.get('noDays')?.value) > minDaysOff) {
                    minDaysOff = Number(selectedEmployees[0].remainingDaysOff);
                    return {
                        removeDaysOff: true,
                    };
                }
            }
            return null;
        };
    }
    /**
     * Validator function to ensure that the difference between two dates is at least a month.
     * @returns A ValidatorFn that checks if the difference between two dates is at least a month.
     */
    static isAtLeastAMonth(): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const controls = (formGroup as FormGroup).getRawValue();
            const start = controls.startDate;
            const end = controls.endDate;

            if (controls.startDate && controls.endDate) {
                const startDate = FormatDate.convertNgbDateToDate(start);
                const endDate = FormatDate.convertNgbDateToDate(end);
                const monthsDifference = FormatDate.countMonthsBetweenDates(startDate, endDate);

                if (monthsDifference < 1) {
                    return { isAtLeastAMonth: true };
                }
            }

            return null;
        };
    }
    /**
     * Validator function to ensure that the role user is selected.
     * @returns A ValidatorFn that checks if the role user is selected.
     */
    static userRoleValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }
            const selectedRoles = control.value;
            const userRolePresent = selectedRoles.some((role: { name: string }) => role.name === 'USER');

            return userRolePresent ? null : { userRoleMissing: true };
        };
    }

    /**
     * Validator that checks if a control contains special characters or letters.
     * Returns an error object if the control contains invalid characters, and null if the control is valid.
     *
     * @returns ValidatorFn that can be used in a FormControl in Angular Forms.
     */
    static specialCharactersValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value === null || control.value === undefined) {
                return null;
            }
            let text = control.value;
            if (control.value instanceof Object) {
                text = FormatDate.convertNgbDateToStringDate(control.value);
            }
            const numericDashRegex = /^[-0-9]*$/;
            if (!numericDashRegex.test(text)) {
                return { invalidCharacters: true };
            }
            return null;
        };
    }
}
