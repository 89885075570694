import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent, LoginTwoFaComponent } from './components';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
    },
    {
        path: 'two-fa',
        component: LoginTwoFaComponent,
        pathMatch: 'full',
    }
    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LoginRoutingModule {}
