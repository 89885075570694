import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationToast, NotificationType } from '../models/toasts.model';

/**
 * The service is responsible for managing and displaying toast notifications.
 * These notifications are used to provide users with visual and temporary
 * feedback in a pleasing manner. The service offers simple methods to display 
 * standard or customized notifications with various types and options.

 */
@Injectable({ providedIn: 'root' })
export class ToastService implements OnDestroy {
    /**
     * BehaviorSubject to emit toast notifications to subscribers.
     */
    private _toastSubject = new BehaviorSubject<NotificationToast | null>(null);

    /**
     * Observable to which components can subscribe to receive toast notifications.
     */
    public get toastSubject(): Observable<NotificationToast | null> {
        return this._toastSubject.asObservable();
    }

    /**
     * Lifecycle hook implementation to remove the toast when the service is destroyed.
     */
    ngOnDestroy(): void {
        this.remove();
    }

    /**
     * Shows a standard toast notification.
     * @param messageKey - The key for the message to be displayed.
     */
    showStandard(messageKey: string): void {
        this.show(messageKey);
    }

    /**
     * Shows a success toast notification.
     * @param messageKey - The key for the message to be displayed.
     * @param translationParams - Optional parameters for translating the message.
     * @param delay - Optional delay before the notification disappears (default is 5000 milliseconds).
     */
    showSuccess(messageKey: string, translationParams?: unknown, delay: number = 5000): void {
        this.show(messageKey, NotificationType.SUCCESS, {
            className: 'bg-success text-light border-success',
            delay,
            translationParams: translationParams ? translationParams : null,
        });
    }

    /**
     * Shows an error toast notification.
     * @param messageKey - The key for the message to be displayed.
     * @param translationParams - Optional parameters for translating the message.
     * @param delay - Optional delay before the notification disappears (default is 5000 milliseconds).
     */
    showError(messageKey: string, translationParams?: unknown, delay: number = 5000): void {
        this.show(messageKey, NotificationType.ERROR, {
            className: 'bg-danger text-light border-danger',
            delay,
            translationParams: translationParams ? translationParams : null,
        });
    }

    /**
     * Shows a warning toast notification.
     * @param messageKey - The key for the message to be displayed.
     * @param translationParams - Optional parameters for translating the message.
     * @param delay - Optional delay before the notification disappears (default is 5000 milliseconds).
     */
    showWarning(messageKey: string, translationParams?: unknown, delay: number = 5000): void {
        this.show(messageKey, NotificationType.WARNING, {
            className: 'bg-warning text-black border-warning',
            delay,
            translationParams: translationParams ? translationParams : null,
        });
    }

    /**
     * Shows an info toast notification.
     * @param messageKey - The key for the message to be displayed.
     * @param translationParams - Optional parameters for translating the message.
     * @param delay - Optional delay before the notification disappears (default is 5000 milliseconds).
     */
    showInfo(messageKey: string, translationParams?: unknown, delay: number = 5000): void {
        this.show(messageKey, NotificationType.INFO, {
            className: 'bg-info text-black border-info',
            delay,
            translationParams: translationParams ? translationParams : null,
        });
    }

    /**
     * Removes the currently displayed toast notification.
     */
    remove(): void {
        this._toastSubject.next(null);
    }

    /**
     * Show a toast notification by type.
     * @param messageKey - The key for the message to be displayed.
     * @param type - The type of the notification (default is standard notification).
     * @param options - Additional options for customizing the notification.
     */
    private show(messageKey: string, type: NotificationType = NotificationType.STANDARD, options = {}): void {
        this._toastSubject.next({ messageKey, type, ...options });
    }
}
