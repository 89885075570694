import { NgModule } from '@angular/core';
import { LoginGuard, PublicAppGuard } from '@axon-dot-fe/core';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent, LoginTwoFaComponent } from './components';
import { LoginRoutingModule } from './login-routing.module';
import {MatCardModule} from '@angular/material/card';

@NgModule({
    declarations: [LoginComponent, LoginTwoFaComponent],
    imports: [ SharedModule, LoginRoutingModule, MatCardModule],
    providers: [LoginGuard, PublicAppGuard],
})
export class LoginModule {}
