import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LoginService } from '@axon/data-access-lib';
import { ToastService } from '@axon/axon-lib';

@Injectable()
export class LoginInterceptorService implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<
        string | null
    >(null);

    constructor(private authService: LoginService, private toastService: ToastService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<object>> {
        let authReq = req;
        const token = this.authService.userLoggedAccess.value
            ? this.authService.userLoggedAccess.value.accessToken
            : null;

        if (
            token !== null &&
            (!['login', 'refresh', 'reset', '2fa/deactivation'].some(url => req.url.includes(url)) ||
                ['login-2fa'].some(url => req.url.includes(url)))
        ) {
            authReq = this.addTokenHeader(req, token);
        }

        return next.handle(authReq).pipe(
            catchError(error => {
                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 401 &&
                    !['login', 'refresh', 'reset', 'login-2fa', 'logout'].some(url => req.url.includes(url))
                ) {
                    //For the case in which an employee is deactivated when he is still logged in with a valid token
                    if (error.error && error.error.code === 'EM013401') {
                        this.authService.logout();
                    } else {
                        return this.handle401Error(authReq, next);
                    }
                }
                return throwError(() => error);
            })
        );
    }

    private handle401Error(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<object>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const refreshToken = this.authService.userLoggedAccess.value
                ? this.authService.userLoggedAccess.value.refreshToken
                : null;

            if (refreshToken) {
                return this.authService.refresh().pipe(
                    switchMap(response => {
                        this.isRefreshing = false;
                        this.refreshTokenSubject.next(response.accessToken);

                        return next.handle(this.addTokenHeader(request, response.accessToken));
                    }),
                    catchError(err => {
                        this.isRefreshing = false;
                        this.toastService.showError('errors.tokenExpiration');
                        this.authService.logout();
                        return throwError(() => err);
                    })
                );
            }
        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap(token => next.handle(this.addTokenHeader(request, token)))
        );
    }

    private addTokenHeader(
        request: HttpRequest<unknown>,
        token: string | null
    ): HttpRequest<object | unknown> {
        return request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
        });
    }
}
