import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LoadingScope } from '../decorators';
import { PermissionsManagement, RoleEmployeeUpdate, RoleManagement } from '../models/admin-management.model';

@Injectable({ providedIn: 'root' })
export class AdminManagementService {
    constructor(private http: HttpClient) {}

    @LoadingScope()
    public createRole(rolesManagement: RoleManagement): Observable<void> {
        return this.http.post<void>(`/api/roles`, rolesManagement);
    }

    @LoadingScope()
    public deleteRole(name: string): Observable<void> {
        return this.http.delete<void>(`/api/roles/${name}`);
    }

    @LoadingScope()
    public getPermissions(): Observable<PermissionsManagement[]> {
        return this.http.get<{ items: PermissionsManagement[] }>(`/api/rights`).pipe(
            map((response) => {
                return response.items;
            })
        );
    }

    @LoadingScope()
    public getRoleById(id: number): Observable<RoleManagement> {
        return this.http.get<RoleManagement>(`/api/roles/${id}`);
    }

    @LoadingScope()
    public getRoles(): Observable<RoleManagement[]> {
        return this.http.get<{ items: RoleManagement[] }>(`/api/roles`).pipe(
            map((response) => {
                return response.items;
            })
        );
    }

    @LoadingScope()
    public updateEmployeeRole(employeeId: number, roleEmployeeUpdate: RoleEmployeeUpdate): Observable<void> {
        return this.http.put<void>(`/api/employees/${employeeId}/roles`, roleEmployeeUpdate);
    }

    @LoadingScope()
    public updateRole(name: string, rolesManagement: RoleManagement): Observable<void> {
        return this.http.put<void>(`/api/roles/${name}`, rolesManagement);
    }
}
