<div class="error-message text-danger mt-1">
    <div
        class="error"
        *ngIf="
            errorMessages &&
            errorMessages.length > 0 &&
            control?.touched &&
            control?.invalid
        "
        [ngStyle]="{ 'background-color': validationErrorBackgroundColor }">
        <ng-container *ngFor="let error of errorMessages; trackBy:identifyErrorByKey">
            {{ error.key | translate : error.params }}
        </ng-container>
    </div>
</div>
