import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { State } from './state';

/**
 * CustomRouterStateSerializer is a class responsible for serializing the router state.
 * It implements the RouterStateSerializer interface to customize the serialization process.
 */
export class CustomRouterStateSerializer implements RouterStateSerializer<State> {
    /**
     * serialize function transforms the router state into a custom State object.
     * It extracts URL, parameters, query parameters, and data from the provided RouterStateSnapshot.
     * @param state - The current RouterStateSnapshot
     * @returns A State object representing the serialized router state
     */
    serialize = (state: RouterStateSnapshot): State => ({
        url: state.url,
        params: this.mergeRouteParams(state.root, ({ params }) => params),
        queryParams: this.mergeRouteParams(state.root, ({ queryParams }) => queryParams),
        data: this.mergeRouteData(state.root),
    });

    /**
     * mergeRouteParams is a private method that merges route parameters recursively.
     * It traverses the route tree and merges parameters for all routes into a single Params object.
     * @param route - The ActivatedRouteSnapshot representing the current route
     * @param getter - A function to extract specific parameters from the route
     * @returns A Params object combining all route parameters
     */
    private mergeRouteParams = (route: ActivatedRouteSnapshot, getter: (activatedRoute: ActivatedRouteSnapshot) => Params): Params =>
        !route
            ? {}
            : {
                  ...getter(route),
                  ...this.mergeRouteParams(
                      (route.children.find(({ outlet }) => outlet === 'primary') || route.firstChild) as ActivatedRouteSnapshot,
                      getter
                  ),
              };

    /**
     * mergeRouteData is a private method that merges route data recursively.
     * It traverses the route tree and merges data for all routes into a single Data object.
     * @param route - The ActivatedRouteSnapshot representing the current route
     * @returns A Data object combining all route data
     */
    private mergeRouteData = (route: ActivatedRouteSnapshot): Data =>
        !route
            ? {}
            : {
                  ...route.data,
                  ...this.mergeRouteData(
                      (route.children.find(({ outlet }) => outlet === 'primary') || route.firstChild) as ActivatedRouteSnapshot
                  ),
              };
}
