import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ENVIRONMENT, Environment } from '@axon-dot/fe/shared/environments';
import { Language, LoginService, allTranslationLanguages } from '@axon/data-access-lib';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { EMPTY, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'ado-core-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild('recaptcha', { static: true }) recaptcha?: RecaptchaComponent;
    credentialsInvalid = false;
    currentYear = new Date().getFullYear();
    inactiveUser = false;
    loginForm = this.formBuilder.nonNullable.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
        rememberMe: [false],
    });
    languages = allTranslationLanguages;
    selectedLanguage: Language = this.languages[0];
    appMode: string = this.env.appMode;

    private loginSubscription = new Subscription();
    private formStatusSubscription = new Subscription();
    private recaptchaAccess = '';

    constructor(
        private loginService: LoginService,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        @Inject(ENVIRONMENT) private env: Environment
    ) {}

    ngOnInit(): void {
        this.formStatusSubscription = this.loginForm.statusChanges.subscribe((status) => {
            if (status === 'INVALID') {
                this.credentialsInvalid = false;
                this.inactiveUser = false;
            }
        });

        const remember = localStorage.getItem('rememberMe') === 'true';
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');

        if (remember && password && username) {
            this.loginForm.patchValue({
                username: username,
                password: password,
                rememberMe: true,
            });
        }
        if (localStorage.getItem('language')) {
            const selectedLanguage = this.languages.find((language) => language.translate === localStorage.getItem('language'));

            if (selectedLanguage) {
                this.selectedLanguage = selectedLanguage;
            }
        }
        if (this.selectedLanguage) {
            this.translate.use(this.selectedLanguage.translate);
            localStorage.setItem('language', this.selectedLanguage.translate);
        }
    }
    ngOnDestroy(): void {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
        if (this.formStatusSubscription) {
            this.formStatusSubscription.unsubscribe();
        }
    }

    identifyLanguageByTranslate(index: number, language: any) {
        return language.translate;
    }

    onLogin(): void {
        if (this.loginForm.value.username && this.loginForm.value.password && this.recaptchaAccess) {
            this.loginSubscription = this.loginService
                .login(this.loginForm.value.username, this.loginForm.value.password)
                .pipe(
                    catchError((errorResponse) => {
                        if (
                            errorResponse &&
                            errorResponse.errorCode &&
                            (errorResponse.errorCode === 'SM002401' || errorResponse.errorCode === 'SM006401')
                        ) {
                            this.credentialsInvalid = true;
                        }
                        if (errorResponse.errorCode && errorResponse.errorCode === 'SM005401') {
                            this.inactiveUser = true;
                        }

                        return EMPTY;
                    })
                )
                .subscribe(() => {
                    if (this.loginForm.value.rememberMe && this.loginForm.value.username && this.loginForm.value.password) {
                        localStorage.setItem('username', this.loginForm.value.username);
                        localStorage.setItem('password', this.loginForm.value.password);
                        localStorage.setItem('rememberMe', 'true');
                    } else {
                        localStorage.removeItem('username');
                        localStorage.removeItem('password');
                        localStorage.removeItem('rememberMe');
                    }
                    this.credentialsInvalid = false;
                    this.inactiveUser = false;
                });
        }
    }

    onChangeLanguage(lang: Language): void {
        this.selectedLanguage = lang;
        this.translate.use(lang.translate);
        localStorage.setItem('language', lang.translate);
    }

    validateRecaptcha(): void {
        this.recaptcha?.execute();
        this.onLogin();
    }

    onResolvedReCaptcha(event: string) {
        this.recaptchaAccess = event;
        this.onLogin();
    }
}
