import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'ado-core-filter-text-field',
    templateUrl: './filter-text-field.component.html',
    styleUrls: ['./filter-text-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterTextFieldComponent implements AfterViewInit, OnChanges {
    @ViewChild('input') input?: ElementRef;
    @Input() initialValue?: string;
    @Output() valueChanged = new EventEmitter<string>();
    @Output() clearFilter = new EventEmitter<boolean>();

    searchForm = this.formBuilder.nonNullable.group({
        search: [''],
    });

    constructor(private readonly formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['initialValue'] && this.initialValue) {
            //If someday we will want to set the search filter as an initial filter, we will want to have "ALL" as a value, but not actually filtering after the "ALL" key (setup in the service).
            this.searchForm.patchValue({ search: this.initialValue !== 'entity.all' ? this.initialValue : ''});
        }
        this.cdr.detectChanges();
    }

    ngAfterViewInit(): void {
        if (this.input) {
            this.input.nativeElement.focus();
            this.cdr.detectChanges();
        }
    }

    onBlur() {
        const searchValue = this.searchForm.get('search')?.value;
        if (searchValue && searchValue.length) {
            this.valueChanged.emit(searchValue);
        } else {
            this.onClearFilter();
        }
    }

    onEnterKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
                this.input?.nativeElement.blur();
        }
    }

    onClearFilter() {
        this.clearFilter.emit(true);
    }
}
