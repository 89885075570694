import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ENVIRONMENT, Environment } from '@axon-dot/fe/shared/environments';
import { DarkModeService, GenericModalComponent, GenericModalDetails } from '@axon/axon-lib';
import { Language, LoginService, Role, UserDetails, UserService, allTranslationLanguages } from '@axon/data-access-lib';

@Component({
    selector: 'ado-core-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    authorizationRole?: Role;
    username?: string;
    teamId?: number;
    userId?: string;
    isAutogeneratedPassword?: boolean;
    isPublicSecured?: boolean;
    isHrDropdownOpen = false;
    isAdminDropdownOpen = false;
    darkMode = {
        isDarkMode: false,
        text: 'dark.light',
        icon: 'fa-sun',
    };

    languages = allTranslationLanguages;
    selectedLanguage: Language = this.languages[0];
    appMode: string = this.env.appMode;
    hideDarkMode: boolean = this.darkModeService.getHideDarkMode();

    private details: GenericModalDetails = {
        header: 'header.disconnect',
        body: 'header.logout-confirm',
        cancelButton: 'entity.cancel',
        confirmButton: 'entity.yes',
    };
    private subscriptions = new Subscription();

    constructor(
        private loginService: LoginService,
        private ngbService: NgbModal,
        private translate: TranslateService,
        private userService: UserService,
        private darkModeService: DarkModeService,
        @Inject(ENVIRONMENT) private env: Environment
    ) {}

    ngOnInit(): void {
        this.isPublicSecured = this.env.appMode === 'PUBLIC';

        this.subscriptions.add(
            this.darkModeService.darkModeSubject$.subscribe((mode) => {
                this.darkMode = mode
                    ? { isDarkMode: mode, text: 'header.dark', icon: 'fa-moon' }
                    : { isDarkMode: mode, text: 'header.light', icon: 'fa-sun text-warning' };
            })
        );
        this.subscriptions.add(
            //TODO: use user details resolver
            this.loginService.userLoggedDetails.subscribe((user) => {
                if (user) {
                    this.userId = user.employeeId;
                    this.teamId = user.teamDetails.id;
                    this.isAutogeneratedPassword = user.isAutogeneratedPassword;
                    this.username = user.username;

                    this.setUserLanguage(user);

                    const role = user.roles;
                    if (role.includes(Role.HR)) {
                        this.authorizationRole = Role.HR;
                    } else if (role.includes(Role.TEAMLEAD)) {
                        this.authorizationRole = Role.TEAMLEAD;
                    } else if (role.includes(Role.USER)) {
                        this.authorizationRole = Role.USER;
                    }
                } else {
                    this.username = undefined;
                    this.authorizationRole = undefined;
                    this.userId = undefined;
                    this.teamId = undefined;
                    this.isAutogeneratedPassword = undefined;
                }
            })
        );
    }

    toggleHrDropdown() {
        this.isHrDropdownOpen = !this.isHrDropdownOpen;
    }

    toggleAdminDropdown() {
        this.isAdminDropdownOpen = !this.isAdminDropdownOpen;
    }

    @HostListener('focusout', ['$event'])
    handleOutsideOrOptionClick() {
        this.isHrDropdownOpen = false;
        this.isAdminDropdownOpen = false;
    }

    identifyLanguageByTranslate(index: number, language: any) {
        return language.translate;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onCheckboxDarkMode() {
        this.darkModeService.setDarkModeSubjectValue(this.darkMode.isDarkMode);
    }

    onLogout(): void {
        const modalRef = this.ngbService.open(GenericModalComponent);
        modalRef.componentInstance.details = this.details;

        modalRef.closed.subscribe((result) => {
            if (result) {
                this.loginService.logout();
            }
        });
    }

    onChangeLanguage(lang: Language): void {
        if (this.userId) {
            this.userService.changeLanguage(this.userId, lang.translate).subscribe(() => {
                localStorage.setItem('language', lang.translate);
                this.translate.use(lang.translate);
                this.selectedLanguage = lang;
            });
        }
    }

    /**
     * Sets the user's language based on preferences stored both in local storage and in the database.
     * Priority is given to the language from local storage if it differs from the language in the database.
     * @param user The user details for whom the language is being set.
     */
    private setUserLanguage(user: UserDetails) {
        const localStorageLanguage = this.languages.find((language) => language.translate === localStorage.getItem('language'));

        const userLanguage = this.languages.find((language) => language.translate === user.preferredLanguage);

        if (userLanguage !== localStorageLanguage && localStorageLanguage) {
            this.selectedLanguage = localStorageLanguage;
        } else if (userLanguage) {
            this.selectedLanguage = userLanguage;
        }
    }
}
