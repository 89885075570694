import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PICTURE_COMPONENTS } from '.';

@NgModule({
    imports: [CommonModule, TranslateModule],
    declarations: [...PICTURE_COMPONENTS],
    exports: [...PICTURE_COMPONENTS],
})
export class PictureModule {}
