import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { LegalDayOff } from '../models';
import { LoadingScope } from '@axon/data-access-lib';
import { Calendar } from '@axon/data-access-lib';

@Injectable({providedIn: 'root'})
export class LegalDaysOffService {
    constructor(private http: HttpClient) {}

    private legalDaysOffByYearBehaviorSubject = new BehaviorSubject<
        Map<number, Map<Calendar, LegalDayOff[]>>
    >(new Map());

    /**
     * @param year
     * @returns an Observable that contains a map with all the calendars of the year
     */
    getLegalDaysOffByYear(
        year: number
    ): Observable<Map<Calendar, LegalDayOff[]> | null> {
        return this.legalDaysOffByYearBehaviorSubject.pipe(
            map(value => value.get(year) ?? null)
        );
    }

    /**
     * Check if there is a year-calendar type key already in the map,
     * if this does not exist then it is brought by a call from the backend.
     * @param year
     * @param employeeCalendar
     * @returns an Observable that contains legal days of the year and the calendar
     */
    getLegalDaysOffAndWeekendsByYearAndCalendar(
        year: number,
        employeeCalendar: Calendar
    ): Observable<LegalDayOff[] | null> {
        if (this.isNewYearAndEmployeeCalendar(year, employeeCalendar)) {
            this.addLegalDaysOffFromNewYearAndEmployeeCalendar(
                year,
                employeeCalendar
            );
        }
        return this.legalDaysOffByYearBehaviorSubject.pipe(
            map(data => data.get(year)?.get(employeeCalendar) || null),
            distinctUntilChanged()
        );
    }

    getLegalDaysOffByYearAndCalendar(year: number, employeeCalendar: Calendar): Observable<LegalDayOff[] | undefined> {
        if (this.isNewYearAndEmployeeCalendar(year, employeeCalendar)) {
            this.addLegalDaysOffFromNewYearAndEmployeeCalendar(
                year,
                employeeCalendar
            );
        }
        return this.legalDaysOffByYearBehaviorSubject.pipe(
            map(data => data.get(year)?.get(employeeCalendar) || null),
            map((legalDays) => legalDays?.filter((value) => value.name !== 'SATURDAY' && value.name !== 'SUNDAY')),
            distinctUntilChanged()
        );
    }

    /**
     * For each calendar, the function brings the legal days
     * from the backend if they have not already been brought
     * @param year
     * @param calendars
     */
    fetchLegalDaysOffByYearAndCalendarsList(
        year: number,
        calendars: Calendar[]
    ): void {
        calendars.forEach(calendar => {
            this.getLegalDaysOffAndWeekendsByYearAndCalendar(year, calendar).subscribe();
        });
    }

    /**
     * This is a wrapper for the loading scope decorator to work
     * @param year
     * @param employeeCalendar
     */
    private addLegalDaysOffFromNewYearAndEmployeeCalendar(
        year: number,
        employeeCalendar: Calendar
    ): void {
        this.fetchLegalDaysOffByYearAndEmployeeCalendar(
            year,
            employeeCalendar
        ).subscribe();
    }

    /**
     *
     * The function fetches the legal days for
     * the year and the calendar from the backend
     * and sends the answer further through a behaviorSubject
     * It is important that this function returns an observable
     * for the loading scope decorator to work
     * @param year
     * @param employeeCalendar
     * @returns an Observable that contains legal days off
     */
    @LoadingScope()
    private fetchLegalDaysOffByYearAndEmployeeCalendar(
        year: number,
        employeeCalendar: Calendar
    ): Observable<LegalDayOff[]> {
        let params = new HttpParams();

        params = params.append('year', year);
        params = params.append('calendar', employeeCalendar);

        return this.http
            .get<{ items: LegalDayOff[] }>(`/api/misc/legal-days`, { params })
            .pipe(
                map(responseData => responseData.items),
                tap(items => {
                    const mapOfYear =
                        this.legalDaysOffByYearBehaviorSubject.value.get(year);
                    if (mapOfYear) {
                        mapOfYear.set(employeeCalendar, items);
                        const newValueOfMap =
                            this.legalDaysOffByYearBehaviorSubject.value;
                        newValueOfMap.set(year, mapOfYear);
                        this.legalDaysOffByYearBehaviorSubject.next(
                            newValueOfMap
                        );
                    } else {
                        const legalDays = new Map<Calendar, LegalDayOff[]>();
                        legalDays.set(employeeCalendar, items);
                        const newValueOfMap =
                            this.legalDaysOffByYearBehaviorSubject.value;
                        newValueOfMap.set(year, legalDays);
                        this.legalDaysOffByYearBehaviorSubject.next(
                            newValueOfMap
                        );
                    }
                })
            );
    }

    private isNewYearAndEmployeeCalendar(
        year: number,
        employeeCalendar: Calendar
    ): boolean {
        return (
            !this.legalDaysOffByYearBehaviorSubject.value ||
            !this.legalDaysOffByYearBehaviorSubject.value
                .get(year)
                ?.get(employeeCalendar)
        );
    }
}
