import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FilterDatepickerFieldComponent } from '../components';

@Directive({
    selector: '[adoCoreFilterDatepickerFocus]',
})
export class FilterEditItemDatepickerFocusDirective implements AfterViewInit {
    @Input() componentRefDate?: FilterDatepickerFieldComponent;
    @Output() stopEditingDate = new EventEmitter<string>();

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        if (this.componentRefDate && this.componentRefDate.input) {
            this.componentRefDate.input.nativeElement.click();
            this.componentRefDate.input.nativeElement.focus();
            this.cdr.detectChanges();
        }
    }

    @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            if (this.componentRefDate?.initialValue) {
                this.stopEditingDate.emit(this.componentRefDate?.initialValue);
            }
        }
    }

    @HostListener('focusout', ['$event']) onBlur(event: FocusEvent) {
        setTimeout(() => {
            if (this.componentRefDate?.selectedDate) {
                this.stopEditingDate.emit(this.componentRefDate?.initialValue);
            } else if (!(this.componentRefDate?.selectedDate || this.componentRefDate?.datepicker?.isOpen())) {
                this.stopEditingDate.emit();
            }
        }, 200);
    }
}
