import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AxonCoreLibModule, TimelineModule } from '@axon/axon-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';
import { PIPES } from './pipes';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { environment } from '@axon-dot-fe/environments';
import { MatTooltipModule } from '@angular/material/tooltip';

const MODULES = [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    AxonCoreLibModule,
    TranslateModule,
    RouterModule,
    FullCalendarModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatTooltipModule,
    TimelineModule
];

@NgModule({
    declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
    imports: [...MODULES],
    exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.siteKey,
            } as RecaptchaSettings,
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useFactory: (translate: TranslateService) => translate.currentLang,
            deps: [TranslateService],
        },
    ],
})
export class SharedModule {}
