import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, map } from 'rxjs';
import { MiscService, OptionSelect, OptionType } from '@axon/data-access-lib';

export const employeeStatusResolver: ResolveFn<OptionSelect[]> = (): Observable<OptionSelect[]> => {
    const miscService = inject(MiscService);

    return miscService.getOptionsSelect(OptionType.EMPLOYEE_STATUS).pipe(
        map<string[], OptionSelect[]>(employeeStatus => {
            return employeeStatus.map(status => {
                return {
                    value: status,
                    label: `lovs.status.${status}`,
                };
            });
        })
    );
};
