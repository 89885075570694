import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { EmployeeFilter, EmployeeService, EmployeesList, StatusEmployees } from '@axon/data-access-lib';
import { Observable, take } from 'rxjs';

export const activeEmployeeListResolver: ResolveFn<EmployeesList[]> = (): Observable<EmployeesList[]> => {
    const filter: EmployeeFilter = {
        status: StatusEmployees.ACTIVE,
    };
    const employeesService = inject(EmployeeService);
    return employeesService.loadEmployeesList(filter).pipe(take(1));
};
