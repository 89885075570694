<div class="modal-content bg-body text-body" [attr.data-bs-theme]="darkMode">
    <div class="modal-header" [ngClass]="details.headerClass">
        <h3>{{ details.header | translate }}</h3>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="details.bodyTemplate">
             <ng-container *ngTemplateOutlet="details.bodyTemplate;"></ng-container>
        </ng-container>
        <br />
        <p *ngIf="!details.bodyTemplate">{{ details.body | translate: details.params }}</p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn"
            [ngClass]="details.cancelButtonClass"
            data-bs-dismiss="modal"
            (click)="onCancel()">
            {{ details.cancelButton | translate }}
        </button>
        <button
            #confirmButton
            type="button"
            class="btn"
            [ngClass]="details.confirmButtonClass"
            data-bs-dismiss="modal"
            (click)="onConfirm()">
            {{ details.confirmButton | translate }}
        </button>
    </div>
</div>
