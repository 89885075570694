import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { LoadingScope } from '@axon/data-access-lib';

@Injectable({
    providedIn: 'root',
})
//TODO: to be removed if the [#1018] issue is resolved on the backend
export class DelayService {
    // Method to add delay to the observable's call
    @LoadingScope()
    addDelay<T>(source: Observable<T>): Observable<T> {
        return source.pipe(delay(200), take(1));
    }
}
