// has-role.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { LoginService } from '../services';
import { UserDetails, Role } from '../models';

@Pipe({
  name: 'hasRole',
  pure: false,
})
export class HasRolePipe implements PipeTransform {
  constructor(private authService: LoginService) {}

  transform(role: string): Observable<boolean> {
    if (!role || !this.authService) {
      return of(false);
    }

    return this.authService.userLoggedDetails.pipe(
      map((userDetails: UserDetails) => {
        const roleEnum: Role = Role[role as keyof typeof Role];
        return userDetails && userDetails.roles && roleEnum
          ? userDetails.roles.includes(roleEnum)
          : false;
      })
    );
  }
}
