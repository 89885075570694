export enum TimelineItemType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  ACCEPTED = 'ACCEPTED',
  NEEDS_WORK = 'NEEDS_WORK',
  COMMENT = 'COMMENT',
  CLONE = 'CLONE',
  DRAFT = 'DRAFT',
  CHECKED = 'CHECKED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  DAYS_OFF_LIMIT_DEFINED = 'DAYS_OFF_LIMIT_DEFINED',
  DAYS_OFF_LIMIT_INCREASED = 'DAYS_OFF_LIMIT_INCREASED',
  DAYS_OFF_LIMIT_DECREASED = 'DAYS_OFF_LIMIT_DECREASED'
}
export interface TimelineItem {
  id: string;
  date: string;
  type: TimelineItemType;
  text: string;
  time?: string;
  bgColor?: string;
  textColor?: string;
  iconClass?: string;
}

export interface CommentItem extends TimelineItem {
  commentatorId: string;
  commentatorName: string;
  commentatorSurname: string;
}
