import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureFlagsService } from '@axon/data-access-lib';
import { map, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceGuard {
    constructor(private router: Router, private featureFlagsService: FeatureFlagsService) {}

    canActivateChild() {
        
        return this.featureFlagsService.isFeatureFlagEnabled$('maintenance').pipe(
            tap(flag => {
                if(flag === true){
                    this.router.navigate(['/maintenance']);
                }
            }),
            map(flag => !flag)
        );
    }
}
