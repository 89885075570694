<ngb-toast
    *ngIf="toast$ | async as toast"
    [class]="toast.className"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove()">
    <button
        type="button"
        class="btn-close btn-close-position"
        [ngClass]="computeBtnCloseClass(toast.type)"
        aria-label="Close"
        (click)="onClose()"></button>
    <div class="notification">
        <i
            class="fa-solid fa-2x"
            [ngClass]="computeNotificationIcon(toast.type)"></i>
        {{ toast.messageKey | translate : toast.translationParams }}
    </div>
</ngb-toast>
