import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemStatus, ItemStepper } from '../../models';

@Component({
    selector: 'ado-core-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnChanges, OnInit, AfterViewInit {
    @Input() nextStep? = 0;
    @Input() steps: ItemStepper[] = [];
    @Output() stepChange = new EventEmitter<number>();
    currentStep = 0;

    constructor(private router: Router, private cdr: ChangeDetectorRef, private route: ActivatedRoute) {}

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }

    ngOnInit(): void {
        this.setPendingStatus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.cdr.detectChanges();
        if (
            this.nextStep === null ||
            this.nextStep === undefined ||
            this.nextStep < 0 ||
            this.nextStep >= this.steps.length ||
            this.nextStep === this.currentStep
        ) {
            return;
        }
        this.cdr.detectChanges();

        this.setPendingStatus();

        if (changes['nextStep'] && changes['nextStep'].previousValue !== changes['nextStep'].currentValue) {
            this.currentStep = this.nextStep;
            this.steps[this.currentStep].status = ItemStatus.IN_PROGRESS;

            if (this.currentStep > 0) {
                this.steps.slice(0, this.currentStep).forEach((step) => {
                    step.status = ItemStatus.COMPLETED;
                });
            }

            this.updateRoute();
        }
    }

    goToStep(step: number) {
        if (this.steps[step].status === ItemStatus.COMPLETED || this.steps[step].status === ItemStatus.IN_PROGRESS) {
            this.stepChange.emit(step);
            this.currentStep = step;
            this.updateRoute();
        }
    }

    private updateRoute() {
        if (this.currentStep < this.steps.length && this.currentStep >= 0) {
            this.router.navigate([this.steps[this.currentStep].route]);
        }
    }

    private setPendingStatus() {
        const statusData = this.route.snapshot.firstChild?.data['cvStatus'];
        if (statusData) {
            const status = statusData.status;

            // Completing all steps from the beginning as completed,
            // then later we will see which ones are pending
            this.steps.slice(0, this.steps.length).forEach((step) => {
                step.status = ItemStatus.COMPLETED;
            });

            switch (status) {
                case 'SKILLS_STEP': {
                    this.steps.slice(0, this.steps.length).forEach((step) => {
                        step.status = ItemStatus.PENDING;
                    });
                    break;
                }
                case 'EXPERIENCE_STEP': {
                    this.steps.slice(1, this.steps.length).forEach((step) => {
                        step.status = ItemStatus.PENDING;
                    });
                    break;
                }
                case 'EDUCATION_STEP': {
                    this.steps.slice(2, this.steps.length).forEach((step) => {
                        step.status = ItemStatus.PENDING;
                    });
                    break;
                }
                case 'LANGUAGES_STEP': {
                    this.steps.slice(3, this.steps.length).forEach((step) => {
                        step.status = ItemStatus.PENDING;
                    });
                    break;
                }
                case 'GENERAL_INFO_STEP': {
                    this.steps.slice(4, this.steps.length).forEach((step) => {
                        step.status = ItemStatus.PENDING;
                    });
                    break;
                }
            }
        }
    }
}
