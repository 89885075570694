import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@axon/axon-lib';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private toastService: ToastService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case HttpStatusCode.Unauthorized:
                        return throwError(() => error);
                    case HttpStatusCode.BadRequest:
                        return throwError(() => error.error);
                    case HttpStatusCode.NotFound:
                        return throwError(() => error.error);
                    case HttpStatusCode.Conflict:
                        return throwError(() => error.error);
                    case HttpStatusCode.Forbidden:
                        return throwError(() => error.error);
                    default:
                        this.toastService.showError('errors.unknown');
                        return throwError(() => error);
                }
            })
        );
    }
}
