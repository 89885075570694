import { Injectable } from '@angular/core';
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { FeatureFlagsService } from '@axon/data-access-lib';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsGuard {
  constructor(private featureFlagsService: FeatureFlagsService,
    private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const featureFlag = next.data.featureFlag;

    return this.checkFeatureFlag(featureFlag);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const featureFlag = route.data?.featureFlag;
    return this.checkFeatureFlag(featureFlag)
      .pipe(
        tap(enabled => {
          if (!enabled) {
            this.navigateToFallback(route);
          }
        })
      )
  }

  private navigateToFallback(route: Route) {
    if (route.data?.fallback) {
      const fallbackPage = route.data?.fallback;
      this.router.navigate([fallbackPage]);
    } else {
      this.router.navigate(['/fallback-page']);
    }
  }

  private checkFeatureFlag(featureFlag: string) {
    return this.featureFlagsService.isFeatureFlagEnabled$(featureFlag);
  }

}