<div ngbDropdown [container]="'body'" [autoClose]="'outside'" class="w-100 position-relative" #myDatepicker>
  <div ngbDropdownToggle class="form-control toggle d-flex align-items-center overflow-hidden w-100"
    id="floatingSelect">
    <ng-container [formGroup]="filterForm" *ngIf="filterForm">
      <input for="floatingSelect" #input class="form-control example" formControlName="date" (click)="onClick($event)"
        (blur)="onBlur()"
        [attr.placeholder]="showPlaceholder ? ('features.filter.search-placeholder' | translate) : ''" />
    </ng-container>
  </div>
  <div ngbDropdownMenu>
    <ngb-datepicker (dateSelect)="onDateSelect($event)" (navigate)="date = $event.next"></ngb-datepicker>
  </div>
</div>