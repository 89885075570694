import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CurrentFilter, FilterField, FilterNgbDropdownOption } from '../../models';
import { TranslateService } from '@ngx-translate/core';

/** Component used for the filter fields */
@Component({
    selector: 'ado-core-filter-edit-item',
    templateUrl: './filter-edit-item.component.html',
    styleUrls: ['./filter-edit-item.component.scss'],
})
export class FilterEditItemComponent implements OnChanges {
    @Input() currentFilter?: CurrentFilter;
    @Input() options?: Map<string | number, FilterField>;
    @Input() categoryOptions?: FilterNgbDropdownOption[];

    @Output() filterEdited = new EventEmitter<CurrentFilter>();
    @Output() filterDeleted = new EventEmitter<boolean>();

    constructor(private translateService: TranslateService) {}

    private translatePrefix = 'features.filter.placeholder';
    categoryPlaceholder = this.translateService.instant(`${this.translatePrefix}.category`);
    operationPlaceholder = this.translateService.instant(`${this.translatePrefix}.operation`);
    valuePlaceholder = this.translateService.instant(`${this.translatePrefix}.value`);
    categoryChanged = false;
    filterConfiguration?: FilterField;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && (changes['currentFilter'] || changes['options']) && this.currentFilter) {
            this.filterConfiguration = this.options?.get(this.currentFilter.category.value);
        }
    }

    /** Method used for operation and value fields to update the current operations and values based on the previous edited category */
    onOptionUpdated(option: FilterNgbDropdownOption, currentStep: 'CATEGORY' | 'OPERATION' | 'VALUE') {
        if (!this.currentFilter) {
            return;
        }

        switch (currentStep) {
            case 'CATEGORY':
                this.categoryChanged = true;
                if (option && this.options && this.currentFilter) {
                    this.currentFilter.category = option;
                    this.filterConfiguration = this.options.get(option.value);
                    this.currentFilter.operation = { value: '=', label: '=' };

                    //TODO: consider to find a better solution for resetting the filter fields
                    this.currentFilter.selectValue = { value: '', label: '' };
                    this.currentFilter.textValue = '';
                    this.currentFilter.dateValue = '';
                }
                break;
            case 'OPERATION':
                this.currentFilter.operation = option;
                this.categoryChanged = false;
                break;
            case 'VALUE':
                this.currentFilter.selectValue = option;
                this.filterEdited.emit(this.currentFilter);
                this.categoryChanged = false;
                break;
        }
    }

    onDateUpdated(option: string, currentStep: 'CATEGORY' | 'OPERATION' | 'VALUE') {
        if (!this.currentFilter) {
            return;
        }

        switch (currentStep) {
            case 'CATEGORY':
                this.categoryChanged = true;
                if (option && this.options && this.currentFilter) {
                    this.currentFilter.category.label = option;
                    this.currentFilter.category.value = option;
                    this.filterConfiguration = this.options.get(option);
                    this.currentFilter.operation = { value: '=', label: '=' };

                    //TODO: consider to find a better solution for resetting the filter fields
                    this.currentFilter.selectValue = { value: '', label: '' };
                    this.currentFilter.textValue = '';
                    this.currentFilter.dateValue = '';
                }
                break;
            case 'OPERATION':
                this.currentFilter.operation.label = option;
                this.currentFilter.operation.value = option;
                this.categoryChanged = false;
                break;
            case 'VALUE':
                this.currentFilter.dateValue = option;
                this.filterEdited.emit(this.currentFilter);
                this.categoryChanged = false;
                break;
        }
    }

    onTextChange(value: string) {
        if (this.filterConfiguration?.fieldType === 'text' && this.currentFilter) {
            this.currentFilter.textValue = value;
            this.filterEdited.emit(this.currentFilter);
        }
    }

    onDeleteFilter() {
        this.filterDeleted.emit(true);
    }
}
