import { Component, Input } from '@angular/core';

@Component({
    selector: 'ado-core-fe-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
    @Input() name?: string;
    @Input() surname?: string;
    @Input() profilePicture?: string;
}
