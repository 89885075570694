import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services';

interface CustomPropertyDescriptor extends PropertyDescriptor {
    timer: NodeJS.Timeout;
}

export function LoadingScope(): MethodDecorator {
    return  (
        target: unknown,
        propertyKey: string | symbol,
        descriptor: PropertyDescriptor
    ) => {
        const original = descriptor.value;
        descriptor.value = function (...args: unknown[]) {

            let timer = (descriptor as CustomPropertyDescriptor).timer;

            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
               LoaderService.showLoader();
            }, 200);

            return original.apply(this, args)
              .pipe(
                finalize(() => {
                  clearTimeout(timer);
                  LoaderService.hideLoader();
                })
              );
        };

        return descriptor;
    };
}
