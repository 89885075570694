<div
    ngbDropdown #dropdownRef
    (keydown)="onKeyDown($event)"
    class="form-floating parent"
    (openChange)="onOpen($event)"
   
>
    <div
        ngbDropdownToggle
        tabindex="0"
        [ngClass]="{ disabled: isDisabled, 'my-dropdown-open': isOpen }"
        class="form-control d-flex align-items-center overflow-hidden"
        id="floatingSelect">
        <img
            *ngIf="selectedOption.srcLeft"
            [src]="selectedOption.srcLeft"
            width="20px"
            height="20px"
            class="me-2 rounded-circle"/>
        {{ selectedOption.label | translate }}
        
        <div
            class="trigger-icon ms-auto"
            [ngClass]="isOpen ? 'rotate-180' : ''">
            <svg
                [ngStyle]="{ width: '24px', height: '24px' }"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19 9L12.0368 15.9632L5.07366 9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>
    </div>

    <div ngbDropdownMenu class="my-dropdown-menu-open">
        <div class="scrollable-class">
            <div
                ngbDropdownItem #item
                *ngFor="let option of options; let i = index; trackBy: identifyOptionByValue"
                class="d-flex justify-content-between align-items-center"
                [ngClass]="{'active-option': i === selectedIndex}"
                (click)="onSelectedOption(option)">
                <div class="d-flex align-items-center">
                    <img
                        *ngIf="option.srcLeft"
                        [src]="option.srcLeft"
                        width="20px"
                        height="20px"
                        class="me-1 rounded-circle"/>
                    {{ option.label | translate }}
                </div>
                <img
                    *ngIf="option.srcRight"
                    [src]="option.srcRight"
                    height="20px"
                    class="ml-auto"/>
            </div>
            <div *ngIf="options?.length === 0" class="no-results">
                {{ 'errors.noResults' | translate }}
            </div>
        </div>
    </div>
    <label for="floatingSelect">{{ placeholder }}</label>
</div>
