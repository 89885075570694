import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AxonCoreLibModule } from '../../core-lib.module';
import { GenericContentComponent } from './components/generic-content/generic-content.component';
import { TimelineComponent } from './components/timeline.component';
import { TIMELINE_PIPES } from './pipes';

@NgModule({
  imports: [CommonModule, AxonCoreLibModule],
  declarations: [
    TimelineComponent,
    GenericContentComponent,
    ...TIMELINE_PIPES,
  ],
  exports: [
    TimelineComponent,
    GenericContentComponent,
  ],
})
export class TimelineModule {}
