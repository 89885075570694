<div cdkDropListGroup>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-container">
          <h5 class="m-2">{{sourceTitle | translate}}</h5>
          <div class="card-body">
            <div cdkDropList [cdkDropListData]="remainingOptions" [cdkDropListDisabled]="disabled"
              class="list-group drag-list" (cdkDropListDropped)="drop($event)">
              <div class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let item of remainingOptions; trackBy: identifyOption" cdkDrag
                [cdkDragDisabled]="item.disabled" [ngClass]="{'team-lead' : item.disabled}" [matTooltipDisabled]="item.name !== 'TEAM_LEADER'" [matTooltip]="'entity.teamLeadDisabled' | translate">
                <span>{{item.name}}</span>
                <span *ngIf="item.description" [matTooltip]="item.description"
                  class="fa fa-info-circle cursor-pointer mx-1"></span>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn" [matTooltipDisabled]="remainingOptions.length === 0" [matTooltip]="'entity.moveAll' | translate" (click)="moveAllToDestination()"
                type="button">
                <i class="fa-solid fa-angles-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card">
        <div class="card-container">
          <h5 class="m-2">{{destinationTitle | translate}}</h5>
          <div class="card-body">
            <div cdkDropList [cdkDropListData]="selectedOptions" [cdkDropListDisabled]="disabled"
              class="list-group drag-list" (cdkDropListDropped)="drop($event)">
              <div class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let item of selectedOptions; trackBy: identifyOption" cdkDrag
                [cdkDragDisabled]="disabled">
                <span>{{item.name}}</span>
                <span *ngIf="item.description" [matTooltip]="item.description"
                  class="fa fa-info-circle cursor-pointer mx-1"></span>
              </div>
            </div>
            <button class="btn" [matTooltipDisabled]="selectedOptions.length === 0" [matTooltip]="'entity.moveAll' | translate" (click)="moveAllToSource()" type="button">
              <i class="fa-solid fa-angles-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>