import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { GenericModalDetails } from '../components/modals/models';
import { GenericModalComponent } from '../components/modals';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard
{
    details: GenericModalDetails = {
        header: 'modal.header',
        body: 'modal.body',
        cancelButton: 'entity.cancel',
        confirmButton: 'entity.yes',
    };

    constructor(private ngbService: NgbModal) {}

    canDeactivate(
        component: ComponentCanDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        if (nextState && nextState.url.includes('login')) {
            return true;
        }
        return component.canDeactivate() ? true : this.openConfirmDialog();
    }

    openConfirmDialog(): Observable<boolean> {
        const modalRef = this.ngbService.open(GenericModalComponent);
        modalRef.componentInstance.details = this.details;
        return modalRef.closed;
    }
}
