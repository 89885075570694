<div ngbDropdown
     [container]="'body'"
     [autoClose]="'outside'"
     class="w-100 position-relative"
     #myDropdown>
  <!-- <label for="floatingSelect">{{ placeholder }}</label> -->
  <div ngbDropdownToggle
       class="form-control toggle d-flex align-items-center overflow-hidden w-100"
       id="floatingSelect">
    <ng-container [formGroup]="filterForm"
                  *ngIf="filterForm">
      <input for="floatingSelect"
             #input
             class="form-control example"
             formControlName="search"
             (click)="onClick($event)"
             (blur)="onBlur()"
             [attr.placeholder]="showPlaceholder ? ('features.filter.search-placeholder' | translate) : ''" />
    </ng-container>
  </div>
  <div ngbDropdownMenu
       class="my-dropdown-menu-open">
    <div class="scrollable-class">
      <div ngbDropdownItem
           class="option"
           *ngFor="let option of filteredOptions; let i = index; trackBy: identifyOptionByValue"
           (click)="onDropdownChange(option, $event)">
        <img *ngIf="option.srcLeft"
             [src]="option.srcLeft"
             width="20px"
             height="20px"
             class="me-1 rounded-circle" />
        {{option.label | translate}}
      </div>
      <div *ngIf="filteredOptions?.length === 0"
           class="no-results">
        {{ 'errors.noResults' | translate }}
      </div>
    </div>
  </div>

</div>