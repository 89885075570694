import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { COMPONENTS } from './components';
import { DataAccessLibModule } from '@axon/data-access-lib';

@NgModule({
    declarations: [...COMPONENTS],
    imports: [SharedModule, DataAccessLibModule],
    exports: [...COMPONENTS],
})
export class UiModule {}
