import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { Environment, ENVIRONMENT } from '@axon-dot/fe/shared/environments';

@Directive({
    selector: '[adoCoreHideDetails]',
})
export class HideDetailsDirective implements OnInit {
    @Input('adoCoreHideDetails') publicDetail!: string;

    constructor(
        private elementRef: ElementRef,
        @Inject(ENVIRONMENT) private env: Environment
    ) {}

    ngOnInit(): void {
        const value = this.elementRef.nativeElement.innerText;

        if (
            this.env.appMode === 'PUBLIC' &&
            this.publicDetail &&
            (!value || value === this.env.personalDetailsPlaceholder)
        ) {
            this.elementRef.nativeElement.innerText = this.publicDetail;
        }
    }
}
