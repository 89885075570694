import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CalendarsDto, OptionType } from '../models';

@Injectable({ providedIn: 'root' })
export class MiscService {
  private miscDataBehaviorSubject = new BehaviorSubject<
    Map<OptionType, string[]>
  >(new Map());

  constructor(private http: HttpClient) {
    console.warn('MiscService Was Born');
  }

  // TODO: test this after enum changes
  getOptionsSelect(type: OptionType): Observable<string[]> {
    if (!this.miscDataBehaviorSubject.value.get(type)) {
      switch (type) {
        case OptionType.CALENDARS:
          this.getCalendars().subscribe();
          break;
        case OptionType.EMPLOYEE_STATUS:
          this.getEmployeeStatus().subscribe();
          break;
        case OptionType.LEAVE_REQUEST_TYPES:
          this.getLeaveRequestTypes().subscribe();
          break;
        case OptionType.USER_ROLES:
          this.getUserRoles().subscribe();
          break;
        case OptionType.LEAVE_REQUEST_STATUS:
          this.getLeaveRequestStatus().subscribe();
          break;
        case OptionType.OPERATION_TYPES:
          this.getOperationTypes().subscribe();
          break;
        default:
          break;
      }
    }

    return this.miscDataBehaviorSubject.asObservable().pipe(
      filter(resp => !!resp.get(type)),
      map(resp => {
        return resp.get(type) as string[];
      })
    );
  }

  private getCalendars(): Observable<CalendarsDto[]> {
    return this.http
      .get<{ items: CalendarsDto[]; }>(`api/misc/calendars`)
      .pipe(
        map(responseData => {
          return responseData.items;
        }),
        tap(responseData => {
          const newMap = this.miscDataBehaviorSubject.value;
          newMap.set(
            OptionType.CALENDARS,
            responseData.map(resp => resp.country)
          );
          this.miscDataBehaviorSubject.next(newMap);
        })
      );
  }

  private getEmployeeStatus(): Observable<string[]> {
    return this.http
      .get<{ items: string[]; }>(`api/misc/employee-status`)
      .pipe(
        map(responseData => {
          return responseData.items;
        }),
        tap(responseData => {
          const newMap = this.miscDataBehaviorSubject.value;
          newMap.set(
            OptionType.EMPLOYEE_STATUS,
            responseData
          );
          this.miscDataBehaviorSubject.next(newMap);
        })
      );
  }

  private getLeaveRequestTypes(): Observable<string[]> {
    return this.http
      .get<{ items: string[]; }>(`api/misc/leave-request-types`)
      .pipe(
        map(responseData => {
          return responseData.items;
        }),
        tap(responseData => {
          const newMap = this.miscDataBehaviorSubject.value;
          newMap.set(
            OptionType.LEAVE_REQUEST_TYPES,
            responseData
          );
          this.miscDataBehaviorSubject.next(newMap);
        })
      );
  }

  private getUserRoles(): Observable<string[]> {
    return this.http.get<{ roleNames: string[] }>(`api/misc/user-roles`).pipe(
      map((responseData) => {
        return responseData.roleNames;
      }),
      tap((responseData) => {
        const newMap = this.miscDataBehaviorSubject.value;
        newMap.set(OptionType.USER_ROLES, responseData);
        this.miscDataBehaviorSubject.next(newMap);
      })
    );
  }

  private getLeaveRequestStatus(): Observable<string[]> {
    return this.http
      .get<{ items: string[] }>(`api/misc/leave-request-status`)
      .pipe(
        map((responseData) => {
          return responseData.items;
        }),
        tap((responseData) => {
          const newMap = this.miscDataBehaviorSubject.value;
          newMap.set(OptionType.LEAVE_REQUEST_STATUS, responseData);
          this.miscDataBehaviorSubject.next(newMap);
        })
      );
  }

  private getOperationTypes(): Observable<string[]> {
    return this.http.get<{ items: string[] }>(`api/misc/operation-types`).pipe(
      map((responseData) => {
        return responseData.items;
      }),
      tap((responseData) => {
        const newMap = this.miscDataBehaviorSubject.value;
        newMap.set(OptionType.OPERATION_TYPES, responseData);
        this.miscDataBehaviorSubject.next(newMap);
      })
    );
  }
}
