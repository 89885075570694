import { Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { NgbDropdownOption } from '../../models/ngb-dropdown-option.model';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ado-core-ngb-select',
    templateUrl: './ngb-select.component.html',
    styleUrls: ['./ngb-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NgbSelectLibComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NgbSelectLibComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgbSelectLibComponent implements OnChanges, ControlValueAccessor, Validator {
    @ViewChild('dropdownRef', { static: false, read: NgbDropdown }) dropdown?: NgbDropdown;
    @ViewChildren('item') dropdownItems?: QueryList<ElementRef>;
    @Input() options?: NgbDropdownOption[];
    @Input() placeholder?: string;
    selectedOption: NgbDropdownOption = { value: '', label: '' };
    isDisabled = false;
    isOpen = false;

    /**
     * Represents the index of the currently selected option in the dropdown.
     * It is initialized to -1 when no option is selected.
     */
    selectedIndex = -1;

    private writtenValue = '';
    private _onChange?: (arg: string | null) => void;
    private _onTouch?: () => void;
    private _onValidation?: () => void;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && this.options) {
            const result = this.options.find((option) => option.value == this.writtenValue);
            if (result) {
                this.selectedOption = result;
            }
        }
        this.cdr.markForCheck();
    }

    identifyOptionByValue(index: number, option: NgbDropdownOption) {
        return option.value;
    }

    writeValue(value: string): void {
        this.writtenValue = value;
        if (this.options) {
            const result = this.options.find((option) => option.value == value);
            if (result) {
                this.selectedOption = result;
            } else {
                this.selectedOption = { value: '', label: '' };
            }
        }
        this.cdr.markForCheck();
    }

    registerOnChange(fn: () => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this.cdr.markForCheck();
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidation = fn;
    }

    validate(): ValidationErrors | null {
        return null;
    }

    onSelectedOption(option: NgbDropdownOption): void {
        this.selectedOption = option;
        this.dropdown?.close();
        this.selectedIndex = -1;

        if (this._onChange && this._onTouch && this._onValidation) {
            this._onChange(option.value.toString());
            this._onTouch();
            // TODO: Check if this is needed and how we should use the Validator interface
            // this._onValidation();
        }
        this.cdr.markForCheck();
    }

    onOpen(isOpen: boolean) {
        this.isOpen = isOpen;
        if (this._onTouch) {
            this._onTouch();
        }
        this.cdr.markForCheck();
    }

    /**
     * Handles keyboard events for the dropdown.
     *
     * This function is called when a keyboard event occurs, such as arrow up, arrow down, or enter,
     * and performs actions accordingly, such as navigating through dropdown options or selecting an option.
     *
     * @param event - The keyboard event object.
     *
     */
    onKeyDown(event: KeyboardEvent) {
        if (!this.options) {
            return;
        }

        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                if (this.selectedIndex > 0) {
                    this.selectedIndex--;
                    this.dropdownItems?.get(this.selectedIndex)?.nativeElement.scrollIntoView(true);
                }
                break;
            case 'ArrowDown':
                event.preventDefault();
                if (this.selectedIndex < this.options.length - 1) {
                    this.selectedIndex++;
                    this.dropdownItems?.get(this.selectedIndex)?.nativeElement.scrollIntoView(true);
                }
                break;
            case 'Enter':
                event.preventDefault();
                if (this.selectedIndex !== -1) {
                    this.onSelectedOption(this.options[this.selectedIndex]);
                }
                break;
            default:
                break;
        }
    }
}
