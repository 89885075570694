import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CurrentFilter, CurrentFilterValue, FilterField, FilterNgbDropdownOption } from '../models';

/** Parent filter component */
@Component({
    selector: 'ado-core-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements OnInit {
    @ViewChild('filterContainer') filterContainer?: ElementRef;

    @Input() filterFields: FilterField[] = []; //the filter fields coming from the parent component where we use this filter component
    @Input() selectedFilters: CurrentFilter[] = []; //the initial filters coming from the parent component where the filter is added
    @Output() activeFilters = new EventEmitter<CurrentFilterValue[]>();
    @Output() searchedValue = new EventEmitter<string>();

    options = new Map<string | number, FilterField>();
    categoryOptions: FilterNgbDropdownOption[] = [];

    ngOnInit(): void {
        this.emitSelectedFilters();
        this.filterFields.forEach((filter) => {
            this.options.set(filter.fieldName.value, filter);
            this.categoryOptions.push(filter.fieldName);
        });
    }

    /* Method used to emit the selected filters */
    emitSelectedFilters() {
        if (this.selectedFilters) {
            this.activeFilters.emit(
                this.selectedFilters.map((filter) => {
                    let value: string | number = '';
                    if (filter && filter.selectValue) {
                        value = filter.selectValue?.value ?? '';
                    }
                    if (filter && filter.textValue) {
                        value = filter.textValue ?? '';
                    }
                    if (filter && filter.dateValue) {
                        value = filter.dateValue ?? '';
                    }
                    return {
                        category: filter.category.value,
                        operation: filter.operation.value,
                        value: value,
                    };
                })
            );
        }
    }

    onOptionSelected(option: CurrentFilter) {
        const currentFilter = option;
        if (this.filterContainer) {
            const containerEl = this.filterContainer.nativeElement as HTMLElement;
            containerEl.scrollLeft = containerEl.scrollWidth - containerEl.clientWidth;
        }
        if (
            currentFilter.category.value &&
            currentFilter.operation.value &&
            (currentFilter.selectValue || currentFilter.textValue || currentFilter.dateValue)
        ) {
            this.removeDuplicateFilters(currentFilter);
            this.selectedFilters.push(currentFilter);
            this.emitSelectedFilters();
        }
    }

    /* Method used to remove the previous filter with the same category as the newly selected one when the singleSelection property is true */
    private removeDuplicateFilters(currentFilter: CurrentFilter) {
        const filterFound = this.filterFields.find((filterField) => filterField.fieldName.value === currentFilter.category.value);
        if (filterFound?.singleSelection) {
            const currentFoundIndex = this.selectedFilters.findIndex((filterField) => filterField === currentFilter);

            const previousIndex = this.selectedFilters.findIndex(
                (filter) => filter.category.value === currentFilter.category.value && filter !== currentFilter
            );
            if (previousIndex !== -1 && previousIndex !== currentFoundIndex) {
                this.selectedFilters.splice(previousIndex, 1);
            }
        }
    }

    //TODO: we will delete it in the future (maybe)
    onOptionUpdated(option: FilterNgbDropdownOption, currentFilter: CurrentFilter) {
        currentFilter.category = option;
        currentFilter.operation = { value: '=', label: '=' };
        currentFilter.selectValue = { value: '', label: '' };
    }

    onFilterEdited(option: CurrentFilter, filter: CurrentFilter) {
        filter.category = option.category;
        filter.operation = option.operation;
        this.removeDuplicateFilters(filter);
        //   filter.value = option.value;
        this.emitSelectedFilters();
    }

    getSearchedValue(value: string) {
        this.searchedValue.emit(value);
    }

    onDeleteFilter(index: number) {
        if (index >= 0 && index < this.selectedFilters.length) {
            this.selectedFilters.splice(index, 1);
            this.emitSelectedFilters();
        }
    }

    onDeleteFilters() {
        if (this.selectedFilters.length > 0) {
            this.selectedFilters.splice(0, this.selectedFilters.length);
            this.emitSelectedFilters();
        }
    }
}
