import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@axon-dot/fe/shared/environments';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class DarkModeService {
    private darkModeSubject = new BehaviorSubject<boolean>(false);
    public darkModeSubject$ = this.darkModeSubject.asObservable();

    constructor(@Inject(ENVIRONMENT) private env: Environment) {
        const darkModeLocalStorage = localStorage.getItem('darkMode');
        if (darkModeLocalStorage) {
            const isDarkMode = darkModeLocalStorage.toLowerCase() === 'true';
            this.darkModeSubject.next(isDarkMode);
        } else {
            this.darkModeSubject.next(false);
        }
    }

    public setDarkModeSubjectValue(value: boolean): void {
        localStorage.setItem('darkMode', value.toString());
        this.darkModeSubject.next(value);
    }

    public getDarkModeSubjectValue(): boolean {
        return this.darkModeSubject.getValue();
    }

    public getHideDarkMode(): boolean {
        return this.env.hideDarkMode;
    }
}
