<div class="filter-item" *ngIf="currentFilter && categoryOptions">
    <!-- CATEGORY -->
    <ado-core-filter-edit-item-label-dropdown [filterField]="currentFilter.category" [options]="categoryOptions"
        [placeholder]="categoryPlaceholder"
        (selectChange)="onOptionUpdated($event, 'CATEGORY')"></ado-core-filter-edit-item-label-dropdown>

    <!-- OPERATION -->
    <ado-core-filter-edit-item-label-dropdown [filterField]="currentFilter.operation"
        [options]="filterConfiguration?.allowedOperations!" [placeholder]="operationPlaceholder"
        (selectChange)="onOptionUpdated($event, 'OPERATION')"></ado-core-filter-edit-item-label-dropdown>

    <!-- VALUE -->
    <ng-container *ngIf="filterConfiguration?.fieldType === 'select' && currentFilter.selectValue">
        <ado-core-filter-edit-item-label-dropdown [filterField]="currentFilter.selectValue"
            [options]="filterConfiguration?.allowedValues!" [placeholder]="valuePlaceholder" [deletable]="true"
            [open]="categoryChanged" (filterDeleted)="onDeleteFilter()"
            (selectChange)="onOptionUpdated($event, 'VALUE')"></ado-core-filter-edit-item-label-dropdown>
    </ng-container>

    <ng-container *ngIf="filterConfiguration?.fieldType === 'date'">
        <ado-core-filter-edit-item-label-datepicker [filterField]="currentFilter.dateValue"
            [placeholder]="valuePlaceholder" [deletable]="true" [open]="categoryChanged"
            (filterDeleted)="onDeleteFilter()"
            (selectChange)="onDateUpdated($event, 'VALUE')"></ado-core-filter-edit-item-label-datepicker>
    </ng-container>

    <ng-container *ngIf="filterConfiguration?.fieldType === 'text'">

        <ado-core-filter-edit-item-label-text [filterField]="currentFilter.textValue" (filterDeleted)="onDeleteFilter()"
            [open]="categoryChanged" (textChanged)="onTextChange($event)"></ado-core-filter-edit-item-label-text>
    </ng-container>
</div>