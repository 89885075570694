<div
    class="alert alert-warning alert-dismissible d-flex align-items-center"
    role="alert"
>
    <ng-container *ngIf="bannerContent">
        <strong
            ><i class="fa-solid fa-triangle-exclamation"></i>
            {{ bannerContent.name | translate }}</strong
        >
        <span>{{ bannerContent.text | translate }}</span>

        <ng-container *ngIf="impersonationMode">
            <div class="m-2"><ng-content></ng-content></div>
        </ng-container>

        <button
            type="button"
            class="btn btn-outline-warning ms-auto"
            (click)="bannerContent.callBack()"
        >
            {{ bannerContent.button | translate }}
        </button>
    </ng-container>
</div>
