import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { MiscService, OptionSelect, OptionType } from '@axon/data-access-lib';
import { Observable, map } from 'rxjs';

export const leaveRequestTypesResolver: ResolveFn<OptionSelect[]> = (): Observable<
    OptionSelect[]
> => {
    const miscService = inject(MiscService);

    return miscService.getOptionsSelect(OptionType.LEAVE_REQUEST_TYPES).pipe(
        map<string[], OptionSelect[]>(leaveRequestTypes => {
            return leaveRequestTypes.map(type => {
                return {
                    value: type,
                    label: `lovs.leave-request-types.${type}`,
                };
            });
        })
    );
};
