import { animate, state, style, transition, trigger } from '@angular/animations';

export const enterLeaveRightLeft = [
    trigger('enterLeaveRightLeft', [
      state('right', style({ transform: 'translateX(0)' })),
      state('left', style({ transform: 'translateX(0)' })),
      transition('void => right', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('void => left', [
        style({ transform: 'translateX(-100%)' }),
        animate(500)
      ]),
      transition('right => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ]),
      transition('left => void', [
        animate(500, style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]