import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

import { FilterDropdownFieldComponent } from '../filter-dropdown-field/filter-dropdown-field.components';
import { FilterTextFieldComponent } from '../filter-text-field/filter-text-field.component';
import { CurrentFilter, FilterField, FilterFieldType, FilterNgbDropdownOption } from '../../models';
import { FilterDatepickerFieldComponent } from '../filter-datepicker-field/filter-datepicker-field.component';

/** Component used for displaying the selected category and operation (when we don't have already a complete filter with category, operation and value)*/
@Component({
    selector: 'ado-core-filter-new-item',
    templateUrl: './filter-new-item.component.html',
    styleUrls: ['./filter-new-item.component.scss'],
})
export class FilterNewItemComponent implements OnChanges {
    @ViewChild('dropdownFilter') dropdownFilter?: FilterDropdownFieldComponent;
    @ViewChild('input') input?: ElementRef;
    @ViewChild('textFilter') textFilter?: FilterTextFieldComponent;
    @ViewChild('datepickerFilter') datepickerFilter?: FilterDatepickerFieldComponent;
    @Input() options = new Map<string | number, FilterField>();
    @Output() filterSelected = new EventEmitter<CurrentFilter>();

    currentFilter: CurrentFilter = {
        category: { value: '', label: '' },
        operation: { value: '', label: '' },
        //  value: { value: '', label: '' },
    };
    currentStep: 'CATEGORY' | 'OPERATION' | 'VALUE' = 'CATEGORY';
    placeholderName = 'Selectati o categorie';
    currentDropdownValues$ = new BehaviorSubject<FilterNgbDropdownOption[]>([]);
    searchForm = this.formBuilder.nonNullable.group({
        search: [''],
    });
    subscriptions = new Subscription();
    fieldType?: FilterFieldType;

    constructor(private readonly formBuilder: FormBuilder) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['options']) {
            this.updateCurrentDropdownValues();
        }
    }

    /** Method which is called when we select an option from the dropdown */
    onOptionSelected(option: FilterNgbDropdownOption) {
        if (!this.currentFilter) {
            return;
        }
        /* Based on the curent step, we are able to set the selected option in the proper field from the filter and to determine the next step */
        if (this.currentStep === 'CATEGORY') {
            this.currentFilter.category = option;
            this.placeholderName = 'Selectati o operatie';
            this.currentStep = 'OPERATION';
            const filterField = this.options.get(this.currentFilter.category.value);
            this.fieldType = filterField?.fieldType;
        } else if (this.currentStep === 'OPERATION') {
            this.currentFilter.operation = option;
            this.placeholderName = 'Selectati o valoare';
            this.currentStep = 'VALUE';
        } else {
            this.currentFilter.selectValue = option;
            this.placeholderName = 'Selectati o categorie';
            this.currentStep = 'CATEGORY';
        }

        /* When we have a complete filter (with category, operation and value selected), we want to emit this filter to the parent and clear the next filter which
        will be the new current one */
        if (
            this.currentFilter.category.value &&
            this.currentFilter.operation.value &&
            this.currentFilter.selectValue &&
            this.currentFilter.selectValue.value
        ) {
            this.filterSelected.emit(this.currentFilter);

            this.currentFilter = {
                category: { value: '', label: '' },
                operation: { value: '', label: '' },
                selectValue: { value: '', label: '' },
            };
        }
        this.updateCurrentDropdownValues();
    }

    onDateSelected(option: string) {
        if (!this.currentFilter) {
            return;
        }
        if (this.currentStep === 'VALUE') {
            this.currentFilter.dateValue = option;
            this.placeholderName = 'Selectati o categorie';
            this.currentStep = 'CATEGORY';
        }

        if (this.currentFilter.category.value && this.currentFilter.operation.value && this.currentFilter.dateValue) {
            this.filterSelected.emit(this.currentFilter);

            this.currentFilter = {
                category: { value: '', label: '' },
                operation: { value: '', label: '' },
                selectValue: { value: '', label: '' },
                textValue: '',
                dateValue: '',
            };
        }
        this.updateCurrentDropdownValues();
    }

    onTextFieldEdit(value: string) {
        if (!this.currentFilter) {
            return;
        }
        /* Based on the curent step, we are able to set the selected option in the proper field from the filter and to determine the next step */
        if (this.currentStep === 'VALUE') {
            this.currentFilter.textValue = value;
            this.placeholderName = 'Selectati o categorie';
            this.currentStep = 'CATEGORY';
        }

        /* When we have a complete filter (with category, operation and value selected), we want to emit this filter to the parent and clear the next filter which
        will be the new current one */
        if (this.currentFilter.category.value && this.currentFilter.operation.value && this.currentFilter.textValue) {
            this.filterSelected.emit(this.currentFilter);

            this.currentFilter = {
                category: { value: '', label: '' },
                operation: { value: '', label: '' },
                textValue: '',
            };
        }
        this.updateCurrentDropdownValues();
    }

    /** Method used to update the current dropdown values based on the current step*/
    updateCurrentDropdownValues() {
        let values: FilterNgbDropdownOption[] | string | number = [];

        if (!this.currentFilter) {
            return;
        }

        switch (this.currentStep) {
            case 'CATEGORY':
                values = [...(this.options.values() || [])].map((operation) => operation.fieldName);
                break;
            case 'OPERATION':
                if (this.currentFilter.category) {
                    values = (this.options.get(this.currentFilter.category.value)?.allowedOperations || []).map((operation) => operation);
                }
                break;
            case 'VALUE': {
                if (!this.currentFilter.category) {
                    break;
                }
                const filterField = this.options.get(this.currentFilter.category.value);
                if (!filterField) {
                    break;
                }

                if (filterField.fieldType === 'text') {
                    values = [];
                }

                if (filterField.fieldType === 'select') {
                    values = (filterField.allowedValues || []).map((value) => value);
                }

                if (filterField.fieldType === 'date') {
                    values = [];
                }
                break;
            }
        }

        this.currentDropdownValues$.next(values);
    }

    /** Method used to clear the current filter when we press ENTER key or we click outside and we don't have all fields from a filter selected */
    clearCurrentFilter(event: boolean) {
        if (!event) {
            return;
        }

        this.currentFilter = {
            category: { value: '', label: '' },
            operation: { value: '', label: '' },
            //  value: { value: '', label: '' },
        };
        this.currentStep = 'CATEGORY';
        this.updateCurrentDropdownValues();
    }
}
