<div class="input-group mb-3">
  <div class="filter-container"
       #filterContainer
       id="input">

    <div class="filter-content">
      <div *ngFor="let filter of selectedFilters; let i=index"
           class="filter-options">
        <ado-core-filter-edit-item [currentFilter]="filter"
                                   [options]="options"
                                   [categoryOptions]="categoryOptions"
                                   (filterDeleted)="onDeleteFilter(i)"
                                   (filterEdited)="onFilterEdited($event, filter)"></ado-core-filter-edit-item>
      </div>
    </div>
    <ado-core-filter-new-item [options]="options"
                              adoCoreFilterNewItemFocus
                              #newFilterComponent
                              [componentRef]="newFilterComponent"
                              (filterSelected)="onOptionSelected($event)">
    </ado-core-filter-new-item>
  </div>
  <div>
    <button type="button"
            class="btn delete-all"
            (click)="onDeleteFilters()">
      <i class="fa-solid fa-circle-xmark"></i>
    </button>
  </div>
</div>