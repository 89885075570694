import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LoginService } from '@axon/data-access-lib';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
    selector: 'ado-root',
    template: ` <router-outlet></router-outlet>
        <ado-core-toasts-container aria-live="polite" aria-atomic="true"> </ado-core-toasts-container>
        <ado-core-loader></ado-core-loader>`,
})
export class AppComponent implements OnInit {
    private languages = ['en', 'ro'];

    constructor(private readonly loginService: LoginService, private readonly translate: TranslateService, private titleService: Title) {}

    ngOnInit(): void {
        this.initializeLanguage();
        this.loginService.autoLogin();
        if (environment.appMode === 'LOCAL') {
            this.titleService.setTitle('Axon | DOT - Protected');
        }
    }

    private initializeLanguage(): void {
        this.translate.addLangs(this.languages);
        this.translate.setDefaultLang('en');

        let language = this.languages[0];
        const selectedLanguage = localStorage.getItem('language');

        if (selectedLanguage && this.languages.includes(selectedLanguage)) {
            language = selectedLanguage;
        }
        this.translate.use(language);
    }
}
