import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'ado-core-filter-edit-item-label-text',
    templateUrl: './filter-edit-item-label-text.component.html',
    styleUrls: ['./filter-edit-item-label-text.component.scss'],
})
export class FilterEditItemLabelTextComponent implements OnChanges {
    @Input() filterField?: string;
    @Input() open = false;
    @Output() filterDeleted = new EventEmitter<boolean>();
    @Output() textChanged = new EventEmitter<string>();
    
    isOpen = false;

    ngOnChanges(changes: SimpleChanges) {
        if(changes && changes['open'] && changes['open'].previousValue !== changes['open'].currentValue){
            this.isOpen = this.open;
        } else {
            this.isOpen = false;
        }
    }

    /** Method used when we start to edit a field from a filter (when we click on a selected option) */
    startEditing(value: string): void {
        this.isOpen = true;
    }

    /** Method used when we stop editing a field from a filter (when we click outside or press ENTER key or another option) */
    stopEditing(value: string) {
        this.isOpen = false;
    }

    onDeleteFilter() {
        this.filterDeleted.emit(true);
        this.isOpen = false;
    }

    onTextChanged(value: string) {
        this.textChanged.emit(value);
        this.isOpen = false;
    }
}
